import { Card } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "rizzui";
import cn from "utils/class-names";
import BuyTable from "./Table/Buy";
import SellTable from "./Table/Sell";
import useFetch from "hooks/use-fetch";
import { Skeleton } from "@nextui-org/react";
import {
  USER_TRANSACTION_LIST,
  USER_TRANSACTION_LIST_URL,
} from "api/userTransaction";

export default function Transaction() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = React.useState(0);
  const [
    userTransactionsQuery,
    {
      data: userTransactions,
      loading: isUserTransactionsLoading,
      error: userTransactionsError,
    },
  ] = useFetch<any>();
  const RenderTabHeader = () => {
    return (
      <div className="flex gap-2">
        <TabList className="flex justify-start space-x-8 border-b border-b-gray-300">
          <Tab
            className={({ selected }: any) =>
              cn(
                "relative w-24 py-2 text-sm outline-none",
                selected
                  ? "font-medium text-gray-900"
                  : "text-gray-500 hover:text-gray-800"
              )
            }
          >
            {({ selected }) => (
              <>
                <p>{t("common.buyStock")}</p>
                <span
                  className={cn(
                    "absolute left-0 -bottom-px h-0.5 w-full",
                    selected ? "bg-gray-900" : "bg-transparent"
                  )}
                />
              </>
            )}
          </Tab>
          <Tab
            className={({ selected }) =>
              cn(
                "relative w-24 py-2 text-sm outline-none",
                selected
                  ? "font-medium text-gray-900"
                  : "text-gray-500 hover:text-gray-800"
              )
            }
          >
            {({ selected }) => (
              <>
                <p>{t("common.sellStock")}</p>
                <span
                  className={cn(
                    "absolute left-0 -bottom-px h-0.5 w-full",
                    selected ? "bg-gray-900" : "bg-transparent"
                  )}
                />
              </>
            )}
          </Tab>
        </TabList>
      </div>
    );
  };

  useEffect(() => {
    console.log("SelamınAleyküm");
    //@ts-ignore
    userTransactionsQuery(USER_TRANSACTION_LIST, {
      endPoint: USER_TRANSACTION_LIST_URL.replace(":page", "1"),
    });
  }, []);


  return (
    <Tabs defaultIndex={activeTab} onChange={setActiveTab}>
      <Card title={t("common.transactionHistory")} extra={<RenderTabHeader />}>
        <TabPanels>
          <TabPanel className="py-2 text-sm leading-6 text-gray-600">
            {isUserTransactionsLoading ? (
              <div>
                {Array.from(Array(10).keys()).map((item, index) => (
                  <div
                    className="max-w-[300px] w-full flex items-center gap-3"
                    key={index}
                  >
                    <div>
                      <Skeleton className="flex rounded-full w-12 h-12" />
                    </div>
                    <div className="w-full flex flex-col gap-2">
                      <Skeleton className="h-3 w-3/5 rounded-lg" />
                      <Skeleton className="h-3 w-4/5 rounded-lg" />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                <BuyTable
                  data={userTransactions?.data?.data.filter(
                    (item: any) => item.type === "buy"
                  )}
                />
              </div>
            )}
          </TabPanel>
          <TabPanel className="py-2 text-sm leading-6 text-gray-600">
            {isUserTransactionsLoading ? (
              <div>
                {Array.from(Array(10).keys()).map((item, index) => (
                  <div
                    className="max-w-[300px] w-full flex items-center gap-3"
                    key={index}
                  >
                    <div>
                      <Skeleton className="flex rounded-full w-12 h-12" />
                    </div>
                    <div className="w-full flex flex-col gap-2">
                      <Skeleton className="h-3 w-3/5 rounded-lg" />
                      <Skeleton className="h-3 w-4/5 rounded-lg" />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                <BuyTable
                  data={userTransactions?.data?.data.filter(
                    (item: any) => item.type === "sell"
                  )}
                />
              </div>
            )}
          </TabPanel>
        </TabPanels>
      </Card>
    </Tabs>
  );
}
