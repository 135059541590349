import { Drawer } from "antd";

import React from "react";
import { Route, Routes } from "react-router-dom";
import LiveTrading from "./Home";


const LiveTradingPage = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<LiveTrading />} />
               
            </Routes>
        </div>
    );
};

export default LiveTradingPage;
