import i18next from 'i18next'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import XHR from 'i18next-http-backend'
import { apiUrl } from 'api'

const i18nOptions = {
   lng: 'de', // varsayılan dil
   fallbackLng: 'de', // yedek dil
   debug: process.env.NODE_ENV === 'development',
   backend: {
      loadPath: apiUrl + '/api/locales/{{lng}}.json' // backend'e dil dosyalarının nereden alınacağını belirtin
   }
}

i18n.use(XHR).use(initReactI18next).init(i18nOptions)

export default i18n
