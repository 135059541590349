import { routes } from 'config/routes'
import { useEffect } from 'react'

export default function ClientLogin() {
   useEffect(() => {
      if (!localStorage.getItem('token')) {
         window.location.href = routes.auth.login
      } else {
         window.location.href = routes.index
      }
   }, [])
   return null
}
