import {
   Button,
   CircularProgress,
   Input,
   Modal,
   ModalBody,
   ModalContent,
   ModalFooter,
   ModalHeader,
   useDisclosure
} from '@nextui-org/react'
import { IconSearch } from '@tabler/icons-react'
import { Card, Form, Select, message } from 'antd'
import { STOCK_LIST, STOCK_LIST_URL, STOCK_STORE, STOCK_STORE_URL } from 'api/stock'
import { USER_STOCK_STORE, USER_STOCK_STORE_URL } from 'api/userStock'
import axios from 'axios'
import ReactSelect from 'components/Select'
import useFetch from 'hooks/use-fetch'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { useDebounce } from "@uidotdev/usehooks";
import { apiUrl } from '../../../api'

function BuyStock() {
   const { t } = useTranslation()
   const [form] = Form.useForm()
   const [messageApi, contextHolder] = message.useMessage()
   const [isAdmin, setIsAdmin] = useState(false)
   const key = 'updatable'

   const [getStockQuery, { data: stockData, loading, error }] = useFetch<any>()
   const [getStockListQuery, { data: stockListData, loading: searchLoading }] = useFetch<any>()

   const [addNewStockMutation, { data: addNewStockData, loading: addNewStockLoading }] = useFetch<any>()

   const [addUserStockMutation, { data: addUserStockData, loading: addUserStockLoading }] = useFetch<any>()

   const [data, setData] = useState<any>([])

   const [selected, setSelected] = useState<any>(null)

   const [modal, setModal] = useState(false)

   const [users, setUsers] = useState<any>([])

   const [search, setSearch] = useState('')
   const debouncedSearchTerm = useDebounce(search, 300);
   const [searchResults, setSearchResults] = useState<any[]>([]);

   const handleAddNewStock = (row: any) => {
      console.log(row)
      messageApi.open({
         key,
         content: t('messages.addingNewStock'),
         duration: 0
      })
      //@ts-ignore
      addNewStockMutation(STOCK_STORE, {
         endPoint: STOCK_STORE_URL,
         method: 'POST',
         body: {
            short_name: row.short_name,
            exchange: row.exchange,
            symbol: row.symbol,
            regular_market_price: row.regular_market_price,
            regular_market_change: row.regular_market_change,
            regular_market_change_percent: row.regular_market_percent_change,
            search_url: row.search_url,
         }
      })
   }

   const handleFinish = (values: any) => {
      console.log(values)
      let object: any = {}

      if (isAdmin) {
         object.historical_price = values.historical_price
         object.user_id = values.user_id
      } else {
         object.historical_price = selected?.regular_market_price
      }

      //@ts-ignore
      addUserStockMutation(USER_STOCK_STORE, {
         endPoint: USER_STOCK_STORE_URL,
         method: 'POST',
         body: {
            stockId: values.stock,
            quantity: values.quantity,
            ...object
         }
      })
   }

   const getUserInfo = async () => {
      const userInfo = JSON.parse(localStorage.getItem('user') || '{}')

      if (userInfo.user_type == 'admin') {
         const { data } = await axios.get('/users-all')
         console.log(data)
         setUsers(data.data)
         setIsAdmin(true)
      }
   }

   useEffect(() => {
      getUserInfo()
      //@ts-ignore
      getStockQuery(STOCK_LIST, {
         endPoint: 'stocks' + '/all-stock'
      })
   }, [])

   /*useEffect(() => {
      setTimeout(() => {
         //@ts-ignore
         getStockListQuery(STOCK_LIST, {
            endPoint: 'stocks' + '/search-stock?query=' + search
         })
      }, 1000)
   }, [search])*/

   React.useEffect(() => {
      const searchHN =  () => {
         let results = [];
         if (debouncedSearchTerm) {
            //@ts-ignore
            getStockListQuery(STOCK_LIST, {
               endPoint: 'stocks' + '/search-stock?query=' + debouncedSearchTerm
            })
         }
      };

      searchHN();
   }, [debouncedSearchTerm]);

   useEffect(() => {
      if (stockData) {
         const newData = stockData?.data?.map((stock: any, index: any) => ({
            value: stock.id,
            label:
               stock.short_name +
               ' (' +
               stock.symbol +
               ')' +
               ' | ' +
               stock.regular_market_price +
               ' | ' +
               stock.regular_market_change +
               ' | ' +
               stock.regular_market_change_percent
         }))

         setData(newData)
      }
   }, [stockData])

   useEffect(() => {
      if (addNewStockData) {
         if (addNewStockData.status == true) {
            messageApi.open({
               key,
               type: 'success',
               content: t('messages.addingNewStockSuccess'),
               duration: 2
            })
         } else {
            messageApi.open({
               key,
               type: 'error',
               content: t('messages.' + addNewStockData.message),
               duration: 2
            })
         }

         //@ts-ignore
         getStockQuery(STOCK_LIST, {
            endPoint: 'stocks' + '/all-stock'
         })
      }
   }, [addNewStockData])

   useEffect(() => {
      if (addNewStockLoading) {
         message.loading(t('messages.addingNewStockSuccess'))
      } else {
         message.destroy()
      }
   }, [])

   useEffect(() => {
      if (addUserStockData) {
         if (addUserStockData.status == true) {
            messageApi.open({
               type: 'success',
               content: t('messages.buyingStockSuccess'),
               duration: 2
            })
         } else {
            messageApi.open({
               type: 'error',
               content: t('messages.' + addUserStockData.message),
               duration: 2
            })
         }
      }
   }, [addUserStockData])

   const { isOpen, onOpen, onOpenChange } = useDisclosure()

   return (
      <div>
         {contextHolder}
         <Modal backdrop="blur" isOpen={isOpen} onOpenChange={onOpenChange} isDismissable={true} size="3xl">
            <ModalContent>
               {onClose => (
                  <>
                     <ModalHeader className="flex flex-col gap-1">{t('messages.modal.title')}</ModalHeader>
                     <ModalBody>
                        <Input
                           onChange={e => setSearch(e.target.value)}
                           size="sm"
                           autoFocus
                           startContent={
                              <IconSearch className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                           }
                           endContent={searchLoading && <CircularProgress />}
                           placeholder={t('search')}
                           variant="faded"
                           classNames={{
                              input: 'outline-none !border-none !bg-transparent !text-gray-900 focus:ring-0 placeholder-gray-500'
                           }}
                        />
                        <div style={{ maxHeight: 500, overflow: 'scroll' }} className="p-0">
                           {stockListData?.data?.map((stock: any, index: any) => (
                              <div
                                 className="flex flex-col gap-1 p-2 border border-gray-100 cursor-pointer hover:bg-gray-100"
                                 onClick={() => {
                                    handleAddNewStock(stock)

                                    onClose()
                                 }}
                                 key={index}
                              >
                                 <span className="text-lg font-bold">
                                    {stock.short_name} ({stock.symbol})
                                 </span>
                                 <span className={stock.regular_market_change > 0 ? 'text-green-500' : 'text-red-500'}>
                                    {stock.regular_market_price} | {stock.regular_market_change} (
                                    {stock.regular_market_change_percent}%)
                                 </span>
                              </div>
                           ))}
                        </div>
                     </ModalBody>
                     <ModalFooter></ModalFooter>
                  </>
               )}
            </ModalContent>
         </Modal>
         <Form form={form} layout="vertical" onFinish={handleFinish}>
            <Form.Item
               label={t('common.selectStock')}
               name="stock"
               rules={[
                  {
                     required: true,
                     message: t('validation.stockId')
                  }
               ]}
            >
               <ReactSelect
                  options={data}
                  placeholder={t('common.selectStock')}
                  loading={loading}
                  onChange={(value: any) => {
                     console.log(value)
                     setSelected(stockData.data.find((stock: any) => stock.id === value.value))
                     form.setFieldsValue({
                        stock: value.value,
                        regular_market_price: value.regular_market_price
                     })
                  }}
               />
               <div className="mt-1 gap-2 flex items-center">
                  <span>{t('common.couldntFind')}</span>
                  <Button variant="ghost" size="sm" onPress={onOpen}>
                     {t('common.clickHere')}
                  </Button>
               </div>
               {selected && (
                  <div className="border border-gray-100 mt-3 p-2">
                     <div className="flex flex-col">
                        <span className="text-lg font-bold">
                           {selected.short_name} ({selected.symbol})
                        </span>
                        <span className={selected.regular_market_change > 0 ? 'text-green-500' : 'text-red-500'}>
                           {selected.regular_market_price} | {selected.regular_market_change} (
                           {selected.regular_market_change_percent}%)
                        </span>
                     </div>
                  </div>
               )}
               <input type="hidden" name="stockId" />
            </Form.Item>
            {isAdmin && (
               <Form.Item
                  label={t('common.user')}
                  name="user_id"
                  rules={[
                     {
                        required: true,
                        message: t('validation.userId')
                     }
                  ]}
               >
                  <Select placeholder={t('common.user')}>
                     {users.map((user: any, index: any) => (
                        <Select.Option key={index} value={user.id}>
                           {user.name} ({user.balance})
                        </Select.Option>
                     ))}
                  </Select>
               </Form.Item>
            )}
            {isAdmin && (
               <Form.Item label={t('common.historicalPrice')} name="historical_price">
                  <NumericFormat
                     placeholder="0.00"
                     className="ant-input rizzui-input"
                     style={{
                        display: isAdmin ? 'block !important' : 'none !important'
                     }}
                     prefix={selected?.currency == 'EUR' ? '€' : selected?.currency == 'USD' ? '$' : '₺'}
                     decimalSeparator="."
                     thousandSeparator=","
                     customInput={Input}
                     value={selected?.regular_market_price}
                     variant="bordered"
                     size="sm"
                     onValueChange={(value: any) => {
                        console.log(value.floatValue)
                        form.setFieldValue('historical_price', value.floatValue)
                     }}
                     classNames={{
                        input: 'outline-none !border-none !bg-transparent !text-gray-900 focus:ring-0 placeholder-gray-500'
                     }}
                  />
                  <input type="hidden" name="historical_price" />
               </Form.Item>
            )}

            <Form.Item
               label={t('common.quantity')}
               name="quantity"
               rules={[
                  {
                     required: true,
                     message: t('validation.quantity')
                  }
               ]}
            >
               <Input
                  size="sm"
                  variant="faded"
                  placeholder={t('common.quantity')}
                  classNames={{
                     input: 'outline-none !border-none !bg-transparent !text-gray-900 focus:ring-0 placeholder-gray-500'
                  }}
               />
            </Form.Item>
            <Button type="submit" color="primary" isLoading={addUserStockLoading}>
               {t('common.buyStock')}
            </Button>
         </Form>
      </div>
   )
}

export default BuyStock
