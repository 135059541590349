import {
   Button,
   CircularProgress,
   Input,
   Modal,
   ModalBody,
   ModalContent,
   ModalFooter,
   ModalHeader,
   Skeleton,
   useDisclosure
} from '@nextui-org/react'
import { IconCalendar, IconPlus, IconQuestionMark, IconSearch } from '@tabler/icons-react'
import { Card, Checkbox, Form, message } from 'antd'
import { STOCK_LIST, STOCK_LIST_URL, STOCK_STORE, STOCK_STORE_URL } from 'api/stock'
import {
   USER_SELL_STOCK_URL,
   USER_STOCK_GROUP_STOCKS_URL,
   USER_STOCK_LIST,
   USER_STOCK_STORE,
   USER_STOCK_STORE_URL
} from 'api/userStock'
import ReactSelect from 'components/Select'
import useFetch from 'hooks/use-fetch'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Text } from 'rizzui'

function SellStock() {
   const { t } = useTranslation()
   const [form] = Form.useForm()
   const [messageApi, contextHolder] = message.useMessage()
   const key = 'updatable'

   const [getStockQuery, { data: stockData, loading, error }] = useFetch<any>()
   const [getStockListQuery, { data: stockListData, loading: searchLoading }] = useFetch<any>()

   const [addNewStockMutation, { data: addNewStockData, loading: addNewStockLoading }] = useFetch<any>()

   const [addUserStockMutation, { data: addUserStockData, loading: addUserStockLoading }] = useFetch<any>()

   const [data, setData] = useState<any>([])

   const [selected, setSelected] = useState<any>(null)

   const [modal, setModal] = useState(false)

   const [search, setSearch] = useState('')

   const [stockRow, setStockRow] = useState<any>(null)

   const [getUserStockByStockIdQuery, { data: userStockData, loading: userStockLoading }] = useFetch<any>()

   const handleFinish = (values: any) => {
      console.log(values)
      if (!stockRow) {
         messageApi.open({
            type: 'error',
            content: t('validation.stockId'),
            duration: 2
         })
         return
      } else if (values.quantity > stockRow?.quantity) {
         message.error(t('validation.maxQuantity'))
         return
      } else {
         //@ts-ignore
         addUserStockMutation(USER_STOCK_STORE, {
            endPoint: USER_SELL_STOCK_URL,
            method: 'POST',
            body: {
               stockId: stockRow?.id,
               quantity: values.quantity
            }
         })
      }
   }

   useEffect(() => {
      //@ts-ignore
      getStockQuery(STOCK_LIST, {
         endPoint: 'user' + '/all-stock'
      })
   }, [])

   useEffect(() => {
      //@ts-ignore
      getStockListQuery(STOCK_LIST, {
         endPoint: 'stocks' + '/search-stock?query=' + search
      })
   }, [search])

   useEffect(() => {
      if (stockData) {
         const newData = stockData?.data?.map((stock: any, index: any) => ({
            value: stock.stock_id,
            label:
               stock.stock.short_name +
               ' (' +
               stock.stock.symbol +
               ')' +
               ' | ' +
               stock.stock.regular_market_price +
               ' | ' +
               stock.stock.regular_market_change +
               ' | ' +
               stock.stock.regular_market_change_percent
         }))

         setData(newData)
      }
   }, [stockData])

   useEffect(() => {
      if (addUserStockLoading) {
         message.loading(t('messages.stockSelling'))
      } else {
         message.destroy()
      }
   }, [])

   useEffect(() => {
      if (addUserStockData) {
         onClose()
         messageApi.open({
            type: addUserStockData.status == true ? 'success' : 'error',
            content: t('messages.stockSoldSuccessfully'),
            duration: 2
         })
         form.resetFields()
      }
   }, [addUserStockData])

   const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure()

   const [sellModalForm] = Form.useForm()

   return (
      <div>
         {contextHolder}
         <Modal
            backdrop="blur"
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            isDismissable={true}
            size="3xl"
            onClose={() => {
               setStockRow(null)
               sellModalForm.resetFields()
               form.resetFields()
            }}
         >
            <ModalContent>
               {onClose => (
                  <Form form={sellModalForm} onFinish={handleFinish} layout="vertical">
                     <ModalHeader className="flex flex-col gap-1">{t('common.sellStock')}</ModalHeader>
                     <ModalBody>
                        {stockRow?.id && (
                           <Alert color="info">
                              <Text className="font-semibold">{t('common.stockInfo')}</Text>
                              <div className="flex flex-col">
                                 <Text>
                                    {stockRow?.stock?.short_name} ({stockRow?.stock?.symbol})
                                 </Text>
                                 <Text>
                                    {t('common.quantity')}: {stockRow?.quantity} x{' '}
                                    {stockRow?.stock?.regular_market_price} ={' '}
                                    {stockRow?.quantity * stockRow?.stock?.regular_market_price}
                                 </Text>
                              </div>
                           </Alert>
                        )}
                        <Form.Item
                           label={t('common.quantity')}
                           name="quantity"
                           rules={[
                              {
                                 required: true,
                                 message: t('validation.quantity')
                              },
                              {
                                 pattern: /^[0-9]*$/,
                                 message: t('validation.quantity')
                              },
                              {
                                 max: stockRow?.quantity,
                                 message: t('validation.maxQuantity')
                              }
                           ]}
                        >
                           <Input
                              size="sm"
                              variant="faded"
                              placeholder={t('common.quantity')}
                              classNames={{
                                 input: 'outline-none !border-none !bg-transparent !text-gray-900 focus:ring-0 placeholder-gray-500'
                              }}
                           />
                        </Form.Item>
                        <Form.Item name="stockId">
                           <div style={{ maxHeight: 500, overflow: 'scroll' }} className="p-0">
                              <div>
                                 {userStockLoading &&
                                    Array.from(Array(10).keys()).map((item, index) => (
                                       <div className=" w-full flex items-center gap-3">
                                          <div className="w-full flex flex-col gap-2">
                                             <Skeleton className="h-3 w-full rounded-lg" />
                                             <Skeleton
                                                className="h-3 w-full mt-2 rounded-lg"
                                                style={{ marginTop: 5 }}
                                             />
                                          </div>
                                       </div>
                                    ))}
                              </div>
                              {userStockData &&
                                 !userStockLoading &&
                                 userStockData?.data
                                    ?.filter((item: any) => item.quantity > 0)
                                    .map((stock: any, index: any) => (
                                       <div
                                          className={`
                        ${stockRow?.id == stock.id ? '!bg-gray-100' : 'bg-white'}
                        flex items-center justify-between gap-1 p-2 border border-gray-100 cursor-pointer hover:bg-gray-100 `}
                                          onClick={() => {
                                             setStockRow(stock)
                                             sellModalForm.setFieldsValue({
                                                stockId: stock.id
                                             })
                                          }}
                                          key={index}
                                       >
                                          <div className="flex flex-col">
                                             <span className="text-lg font-bold">
                                                {stock.stock.short_name} ({stock.stock.symbol})
                                             </span>
                                             <span
                                                className={
                                                   stock.regular_market_change > 0 ? 'text-green-500' : 'text-red-500'
                                                }
                                             >
                                                {stock.stock.regular_market_price} | {stock.stock.regular_market_change}{' '}
                                                ({stock.stock.regular_market_change_percent}%)
                                             </span>
                                             <span className="mt-2 flex gap-2">
                                                <IconCalendar size={18} />{' '}
                                                {moment(stock.created_at).format('DD.MM.YYYY HH:mm')}
                                             </span>
                                          </div>
                                          <div>
                                             {t('common.quantity')}:{stock.quantity}
                                          </div>
                                       </div>
                                    ))}
                           </div>
                        </Form.Item>
                     </ModalBody>
                     <ModalFooter>
                        <Button
                           color="danger"
                           onClick={() => {
                              onClose()
                           }}
                        >
                           {t('buttons.cancel')}
                        </Button>
                        <Button color="primary" type="submit">
                           {t('buttons.confirm')}
                        </Button>
                     </ModalFooter>
                  </Form>
               )}
            </ModalContent>
         </Modal>
         <Form form={form} layout="vertical" onFinish={() => {}}>
            <Form.Item
               label={t('common.sellStock')}
               name="stock"
               rules={[
                  {
                     required: true,
                     message: t('validation.stockId')
                  }
               ]}
            >
               <ReactSelect
                  options={data}
                  loading={loading}
                  onChange={(value: any) => {
                     console.log(value)
                     form.setFieldsValue({
                        stock: value.value
                     })
                     onOpen()
                     setSelected(stockData.data.find((stock: any) => stock.id === value.value))
                     //@ts-ignore
                     getUserStockByStockIdQuery(USER_STOCK_LIST, {
                        endPoint: USER_STOCK_GROUP_STOCKS_URL + '?stockId=' + value.value
                     })
                  }}
                  value={form.getFieldValue('stock')}
               />

               <input type="hidden" name="stockId" />
            </Form.Item>
         </Form>
      </div>
   )
}

export default SellStock
