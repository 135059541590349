import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Loader, Tab, TabList, TabPanel, TabPanels, Tabs } from 'rizzui'
import cn from 'utils/class-names'
import BuyTable from './Table/Buy'
import SellTable from './Table/Sell'
import useFetch from 'hooks/use-fetch'
import { Card, Skeleton } from '@nextui-org/react'
import { USER_ACTIVITY_LIST, USER_ACTIVITY_LIST_URL } from 'api/userActivity'
import moment from 'moment'

export default function Activities() {
   const { t } = useTranslation()
   const [activeTab, setActiveTab] = React.useState(0)
   const [
      userActivitiessQuery,
      { data: userActivitiess, loading: isUserActivitiessLoading, error: userActivitiessError }
   ] = useFetch<any>()

   useEffect(() => {
      //@ts-ignore
      userActivitiessQuery(USER_ACTIVITY_LIST, {
         endPoint: USER_ACTIVITY_LIST_URL.replace(':page', '1')
      })
   }, [])

   return (
      <Card title={t('aside.activities')}>
         <ol className="relative border-s border-gray-200 dark:border-gray-700">
            {userActivitiess?.data?.data.map((item: any, index: number) => (
               <li className="mb-10 ms-4" key={index}>
                  <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700" />
                  <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                     {moment(item.created_at).format('DD/MM/YYYY HH:mm:ss')}
                  </time>

                  <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">{item.activity}</p>
               </li>
            ))}
         </ol>
         {/* <table className="w-full text-sm text-left rtl: text-gray-500 dark:text-gray-400 px-10">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr className={""}>
            <th key={1} className={cn("px-2 py-2 font-semibold")}>
              <div>{t("common.date")}</div>
            </th>
            <th key={2} className={cn("px-2 py-2 font-semibold")}>
              <div>{t("common.activity")}</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {isUserActivitiessLoading && (
            <tr>
              <td colSpan={2}>
                <div className="flex justify-center items-center h-20">
                  <Loader />
                </div>
              </td>
            </tr>
          )}
          {!isUserActivitiessLoading &&
            userActivitiess?.data?.data?.length == 0 && (
              <tr>
                <td colSpan={2}>
                  <div className="flex justify-center items-center h-20">
                    <span className="text-gray-400 dark:text-gray-600">
                      {t("common.noData")}
                    </span>
                  </div>
                </td>
              </tr>
            )}
        </tbody>
        {userActivitiess?.data?.data?.map((item: any, index: number) => (
          <tr>
            <td className="px-2 py-2 font-semibold">
              <div className="">
                {moment(item.created_at).format("DD/MM/YYYY")}
              </div>
            </td>
            <td className="px-2 py-2 font-semibold">
              <div className="">{item.activity}</div>
            </td>
          </tr>
        ))}
      </table> */}
      </Card>
   )
}
