import React, { useEffect } from "react";

const Trading = () => {
  

  return (
    <iframe
      src='https://www.tradingview-widget.com/embed-widget/forex-cross-rates/?locale=de_DE#%7B"width"%3A"100%25"%2C"height"%3A600%2C"currencies"%3A%5B"EUR"%2C"USD"%2C"JPY"%2C"GBP"%2C"CHF"%2C"AUD"%2C"CAD"%2C"NZD"%5D%2C"isTransparent"%3Afalse%2C"colorTheme"%3A"light"%2C"utm_source"%3A"localhost"%2C"utm_medium"%3A"widget"%2C"utm_campaign"%3A"forex-cross-rates"%2C"page-uri"%3A"localhost%3A3000%2Fdashboard%2Findex"%7D'
      width={"100%"}
      height={600}
    />
  );
};

export default Trading;
