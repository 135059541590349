import React, { useEffect } from "react";

import { Card, Drawer, Modal } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "hooks/use-fetch";
import { INVESTED_SHOW, INVESTED_UPDATE, INVESTED_UPDATE_URL } from "api/invested";
import TableComponents from "components/Table";
import { useTranslation } from "react-i18next";
import { Button } from "@nextui-org/react";
import { MoneyFormat2 } from "hooks/currency";
import moment from "moment";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { IconDownload } from "@tabler/icons-react";
import ReactToPrint from 'react-to-print';



const PaymentPlan = () => {
    const auth = JSON.parse(localStorage.getItem('user') as any);


    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams<any>();
    const [paymentsQuery, { data: paymentsData, loading: paymentsLoading, error: paymentsError }] = useFetch<any>();

    const [updateQuery, { data: updateData, loading: updateLoading, error: updateError }] = useFetch<any>();

    useEffect(() => {
        //@ts-ignore
        paymentsQuery(INVESTED_SHOW, {
            endPoint: 'invested-payment-plans/' + id,
        });
    }, [id]);


    useEffect(() => {
        if (updateData) {
            //@ts-ignore
            paymentsQuery(INVESTED_SHOW, {
                endPoint: 'invested-payment-plans/' + id,
            });
        }
    }, [updateData]);

    const tableRef = React.useRef<any>();


    return (
        <Drawer
            title={<h1>
                {t('common.paymentPlan')}
            </h1>}
            placement="bottom"
            height={"100%"}

            onClose={() => {
                navigate(-1);
            }}
            open={true}
            extra={
                <ReactToPrint
                    trigger={() => {
                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                        // to the root node of the returned component as it will be overwritten.
                        return <Button size="lg" color="success" variant="solid" className="mr-2">
                            <IconDownload size={24} />
                            {t('common.download')}
                        </Button>;
                    }}
                    content={() => tableRef.current}
                />
            }
        >
            <Card loading={paymentsLoading} bodyStyle={{
                padding: 0,
                borderRadius: 20
            }} rootClassName="p-0" style={{ padding: 0 }}>
                <div>
                    <table
                        ref={tableRef}
                        className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 px-10">
                        <thead className="text-md text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr className={''}>
                                <td className={'px-5 py-1 font-semibold'}>
                                    {t('common.paymentPlanType')}
                                </td>
                                <td className={'p-1 font-semibold'}>
                                    {t('common.payDate')}
                                </td>
                                <td className={'p-1 font-semibold'}>
                                    {t('common.profitAmount')}
                                </td>
                                <td className={'p-1 font-semibold'}>
                                    {t('common.remainingAmount')}
                                </td>
                                <td className={'p-1 font-semibold'}>
                                    {t('common.taxRate')}
                                </td>
                                {/* Remaining Principal (Total with Profit) */}
                                <td className={'p-1 font-semibold'}>
                                    {t('common.remainingPrincipal')}
                                </td>
                                <td className={'p-1 font-semibold'}>
                                    {t('common.payedAmount')}
                                </td>
                                <td className={'py-1 px-5 font-semibold'}>
                                    {t('common.status')}
                                </td>
                            </tr>
                        </thead>
                        <tbody className="max-h-96 overflow-y-scroll">
                            {paymentsData?.result?.dates.map((row: any, index: number) => {
                                //(record.rate * record.amount) / 100
                                let price = (paymentsData?.result?.row?.rate * paymentsData?.result?.row?.amount) / 100;

                                let totalPrice = price + paymentsData?.result?.row?.amount;

                                let totalDates = paymentsData?.result?.dates.length;

                                const toplamBorc = price;

                                const toplamBorcOranTutar = totalPrice;

                                // Haftalık ödeme planı için hafta sayısı
                                const haftaSayisi = (totalDates);

                                // Her hafta ödenmesi gereken miktar
                                const haftalikOdenenMiktar = toplamBorc / haftaSayisi;

                                const haftalikAzalanMiktar = toplamBorc / haftaSayisi;

                                const haftalikAzalanMiktarOranTutar = totalPrice / haftaSayisi;

                                // Toplam ödenen miktarı saklamak için bir dizi
                                let toplamOdenenMiktarlar = [];

                                let toplamOdenenMiktarTers = [];

                                let toplamOdenenMiktarlarTersArtiTutar = [];

                                // Haftalık ödemeleri hesapla ve toplam miktarları güncelle
                                for (let i = 1; i <= haftaSayisi; i++) {
                                    const odenenMiktar = haftalikOdenenMiktar * i;
                                    toplamOdenenMiktarlar.push(odenenMiktar);
                                }

                                for (let i = 0; i <= haftaSayisi; i++) {
                                    const kalanBorc = toplamBorc - haftalikAzalanMiktar * i;
                                    toplamOdenenMiktarTers.push(kalanBorc);
                                }

                                for (let i = 0; i <= haftaSayisi; i++) {
                                    const kalanBorc = toplamBorcOranTutar - haftalikAzalanMiktarOranTutar * i;
                                    toplamOdenenMiktarlarTersArtiTutar.push(kalanBorc);
                                }

                                var sayi = (paymentsData?.result?.row?.rate / (totalDates)).toString().substring(0, 6) as any;
                                let rate = paymentsData?.result?.row?.rate / (totalDates);

                                sayi = sayi.toString();

                                // 5. rakam 1-4 arasında ise sabit kalacak, 5-9 arasında ise bir üst rakama yuvarlanacak

                                let find5 = sayi.split('.')[1].split('')[4];

                                let bol = sayi.split('.')[1].split('');

                                let array = [];

                                array.push(sayi.split('.')[0]);

                                array.push('.');

                                for (let i = 0; i < bol.length; i++) {
                                    if (i == 3) {
                                        if (parseInt(bol[i]) >= 5) {
                                            array.push(parseInt(bol[i]) + 1);
                                        }
                                    }
                                    else {
                                        array.push(bol[i]);
                                    }
                                }






                                let payment_plan_type;

                                if (paymentsData?.result?.row?.payment_plan_type == 1) {
                                    payment_plan_type = 'week';
                                } else if (paymentsData?.result?.row?.payment_plan_type == 2) {
                                    payment_plan_type = 'month';
                                } else if (paymentsData?.result?.row?.payment_plan_type == 3) {
                                    payment_plan_type = 'four_month';
                                } else if (paymentsData?.result?.row?.payment_plan_type == 4) {
                                    payment_plan_type = 'six_month';
                                } else if (paymentsData?.result?.row?.payment_plan_type == 5) {
                                    payment_plan_type = 'twelve_month';
                                }


                                // Sonucu yazdır


                                return (
                                    <tr
                                        id={'payment-plan-row-' + index}
                                        key={index}
                                        className="border-b border-gray-100 dark:border-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700"
                                    >
                                        <td className={'py-1 px-5'}>
                                            {index + 1}. {t('common.' + payment_plan_type)}
                                        </td>
                                        <td className={'p-1'}>
                                            {moment(row?.date).format('DD.MM.YYYY')}
                                        </td>

                                        <td className={'p-1'}>
                                            {MoneyFormat2(toplamOdenenMiktarTers[index])}
                                        </td>
                                        <td className={'p-1'}>
                                            {/* {(yeniSayi).toString().substring(0, 6)}<br />
                                            {rate} */}

                                            {rate.toString().length > 5 ? array.join('') : rate.toString().substring(0, 6)}
                                        </td>
                                        <td className={'p-1'}>
                                            0%
                                        </td>
                                        <td className={'p-1'}>
                                            {MoneyFormat2(toplamOdenenMiktarlarTersArtiTutar[index])}
                                        </td>
                                        <td className={'p-1'}>
                                            {MoneyFormat2(toplamOdenenMiktarlar[index])}
                                        </td>
                                        <td className={'py-1 px-5'}>
                                            <Button
                                                className="w-full"
                                                onClick={() => {
                                                    if (auth.user_type == "user") {
                                                        return;
                                                    }
                                                    Modal.confirm({
                                                        closable: true,

                                                        title: t('messages.areYouSure'),
                                                        content: row?.status ? t('messages.thisRecordWillBeUpdateAsUnpaid') : t('messages.thisRecordWillBeUpdateAsPaid'),
                                                        onOk() {
                                                            //@ts-ignore
                                                            updateQuery(INVESTED_UPDATE, {
                                                                endPoint: 'invested-payment-plans',
                                                                method: 'PUT',
                                                                body: {
                                                                    date: row?.date,
                                                                    invested_id: id,
                                                                }
                                                            });
                                                        },
                                                        okText: t('buttons.yes'),
                                                        cancelText: t('buttons.no'),
                                                        okButtonProps: {
                                                            loading: updateLoading
                                                        },
                                                        onCancel() {
                                                            console.log('Cancel');
                                                        },
                                                    });
                                                }}
                                                size="md" color={row?.status ? "success" : "danger"} variant="solid">
                                                {row?.status ? t('common.paid') : t('common.unpaid')}
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div style={{ height: 60 }}></div>
            </Card>
        </Drawer>
    );
}

export default PaymentPlan;
