import { Radio, RadioGroup, SelectItem } from '@nextui-org/react'
import { Form, Select } from 'antd'
import { USER_LIST } from 'api/user'
import ReactSelect from 'components/Select'
import { MoneyFormat } from 'hooks/currency'
import useFetch from 'hooks/use-fetch'
import React, { useEffect, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { Input } from 'rizzui'

interface Props {
   onFinish: any
   initialValues?: any
   isLoading?: boolean
   form: any
}
export default function IposForm(props: Props) {
   const { t } = useTranslation()
   const [usersQuery, { data: usersData, loading: usersLoading, error: usersError }] = useFetch<any>()

   const [page, setPage] = useState(1)

   useEffect(() => {
      //@ts-ignore
      usersQuery(USER_LIST, {
         endPoint: 'users-all'
      })
   }, [])

   useEffect(() => {
      props.form.setFieldValue('user_id', props.initialValues?.user_id)
      props.form.setFieldValue('name', props.initialValues?.name)
      props.form.setFieldValue('description', props.initialValues?.description)
      props.form.setFieldValue('pieces', props.initialValues?.pieces)
      props.form.setFieldValue('price', props.initialValues?.price)
      props.form.setFieldValue('contact_date', props.initialValues?.contact_date)
      props.form.setFieldValue('wkn', props.initialValues?.wkn)
      props.form.setFieldValue('profit', props.initialValues?.profit)
      props.form.setFieldValue('bookBuilding', props.initialValues?.bookBuilding)
   }, [props.initialValues])

   return (
      <div>
         <Form.Item
            rules={[
               {
                  required: true,
                  message: t('validation.user_required')
               }
            ]}
            label={t('common.user')}
            name="user_id"
         >
            <Select loading={usersLoading} defaultValue={props.initialValues?.user_id}>
               {usersData?.data?.map((user: any) => (
                  <Select.Option value={user.id}>
                     {user.name + " " + user.last_name} - {MoneyFormat(user.balance)}
                  </Select.Option>
               ))}
            </Select>
         </Form.Item>

         <Form.Item
            rules={[
               {
                  required: true,
                  message: t('validation.name')
               }
            ]}
            label={t('common.name')}
            name="name"
         >
            <Input
               value={props.initialValues?.description}
               placeholder={t('common.name')}
               className="ant-input rizzui-input"
            />
         </Form.Item>
         <Form.Item
            rules={[
               {
                  required: true,
                  message: t('validation.wkn')
               }
            ]}
            label={t('common.wkn')}
            name="wkn"
         >
            <Input value={props.initialValues?.wkn} placeholder={t('common.wkn')} className="ant-input rizzui-input" />
         </Form.Item>

         <Form.Item
            rules={[
               {
                  required: true,
                  message: t('validation.pieces_required')
               }
            ]}
            label={t('common.pieces')}
            name="pieces"
         >
            <Input
               value={props.initialValues?.pieces}
               placeholder={t('common.pieces')}
               className="ant-input rizzui-input"
            />
         </Form.Item>

         <Form.Item
            rules={[
               {
                  required: true,
                  message: t('validation.price_required')
               }
            ]}
            label={t('common.price')}
            name="price"
         >
            <NumericFormat
               placeholder="0.00"
               className="ant-input rizzui-input"
               prefix="€"
               decimalSeparator="."
               thousandSeparator=","
               customInput={Input}
               value={props.initialValues?.price}
               onValueChange={(value: any) => {
                  console.log(value.floatValue)
                  props.form.setFieldValue('price', value.floatValue)
               }}
            />
            <input type="hidden" name="price" />
         </Form.Item>
         <Form.Item
            rules={[
               {
                  required: true,
                  message: t('validation.bookBuilding_required')
               }
            ]}
            label={t('common.bookBuilding')}
            name="bookBuilding"
         >
            <Input />
         </Form.Item>

         {/* <Form.Item
        rules={[
          {
            required: true,
            message: t("validation.profit"),
          },
        ]}
        label={t("common.profit")}
        name="profit"
      >
        <NumericFormat
          placeholder="0.00"
          className="ant-input rizzui-input"
          prefix="€"
          decimalSeparator="."
          thousandSeparator=","
          customInput={Input}
          value={props.initialValues?.profit}
          onValueChange={(value: any) => {
            console.log(value.floatValue);
            props.form.setFieldValue("profit", value.floatValue);
          }}
        />
        <input type="hidden" name="prprofitice" />
      </Form.Item> */}

         <Form.Item
            rules={[
               {
                  required: true,
                  message: t('validation.contact_date_required')
               }
            ]}
            label={t('common.contactDate')}
            name="contact_date"
         >
            <Input
               type="date"
               value={props.initialValues?.contact_date}
               placeholder={t('common.contact_date')}
               className="ant-input rizzui-input"
            />
         </Form.Item>
      </div>
   )
}
