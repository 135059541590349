import React, { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { MoneyFormat, MoneyFormat2, PriceFormat } from 'hooks/currency'
import pusherJs from 'pusher-js'
import { Loader } from 'rizzui'
import cn from 'utils/class-names'

interface TableProps {
   data: any[]
   bottomContent?: any
   topContent?: any
   isLoaded?: boolean
   stockData?: any
}

export default function StockTable(props: TableProps) {
   const { t } = useTranslation()
   const [diffrentPath, setDiffrentPath] = React.useState<any>(null)

   useEffect(() => {
      const path = window.location.pathname
      const findString = '/dashboard/my-stocks/without-currency'
      const isFind = !path.includes(findString)
      setDiffrentPath(isFind)
   }, [window.location.pathname])

   const columns = [
      {
         name: t('common.stockName'),
         uid: 'stockName',
         className: 'text-start capitalize'
      },
      {
         name: t('common.quantity'),
         uid: 'quantity',
         className: 'flex items-center justify-end capitalize'
      },
      {
         name: t('common.regularMarketPriceUnit'),
         uid: 'historicalPrice',
         className: 'flex items-center justify-end capitalize'
      },
      {
         name: t('common.regularMarketPrice'),
         uid: 'regularMarketPrice',
         className: 'flex items-center justify-end capitalize'
      },

      // { name: t("common.fx"), uid: "isFx" },

      {
         name: t('common.regularMarketChange'),
         className: 'flex items-center justify-end capitalize',
         uid: 'regularMarketChange'
      },

      {
         name: t('common.historicalTotalPrice'),
         uid: 'historicalTotalPrice',
         className: 'flex items-center justify-end capitalize'
      },
      {
         name: t('common.totalValue'),
         uid: 'totalValue',
         className: 'flex items-center justify-end capitalize'
      },
      {
         name: t('common.historicalPriceChange'),
         uid: 'historicalPriceChange',
         className: 'flex items-center justify-end capitalize'
      },
      {
         name: t('common.actionDate'),
         uid: 'actionDate',
         className: 'flex items-center justify-end capitalize'
      }
   ]

   const getCurrency = (price: string, format: string, isConvert?: Boolean) => {
      if (!isConvert) {
         return price
      } else {
         console.log('price', price)
         return PriceFormat(price, format)
      }
   }

   const [stockData, setStockData] = React.useState<any>([])

   useEffect(() => {
      const pusher = new pusherJs('614ec570899f6dada256', {
         cluster: 'eu'
      })
      const channel = pusher.subscribe('tablo-guncelleme')

      channel.bind('tablo-guncelleme', function (data: any) {
         setStockData(data.stock)
      })
   }, [])

   const navigate = useNavigate()
   const { data } = props

   return (
      <div>
         <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 px-10">
               <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr className={''}>
                     {columns.map(column => (
                        <th key={column.uid} className={cn('px-2 py-2 font-semibold')}>
                           <div className={cn('text-right', column.className)}>{column.name}</div>
                        </th>
                     ))}
                  </tr>
               </thead>
               <tbody>
                  {props.isLoaded && (
                     <tr>
                        <td colSpan={columns.length}>
                           <div className="flex justify-center items-center h-20">
                              <Loader />
                           </div>
                        </td>
                     </tr>
                  )}
                  {!props.isLoaded && data.length === 0 && (
                     <tr>
                        <td colSpan={columns.length}>
                           <div className="flex justify-center items-center h-20">
                              <span className="text-gray-400 dark:text-gray-600">{t('common.noData')}</span>
                           </div>
                        </td>
                     </tr>
                  )}
                  {data
                     .filter((item: any) => item.quantity > 0)
                     .map(item => {
                        const stockFind = stockData?.find((stock: any) => stock.id == item.stock.id)
                        if (!stockFind) {
                           return null
                        } else {
                           const regularMarketPrice = MoneyFormat2(
                              getCurrency(stockFind?.regular_market_price, stockFind?.currency, diffrentPath),
                              diffrentPath ? 'EUR' : stockFind?.currency == '$' ? 'USD' : stockFind?.currency
                           ) as any

                           let totalMarketPrice = 0
                           totalMarketPrice =
                              item.quantity *
                              getCurrency(stockFind?.regular_market_price, stockFind?.currency, diffrentPath)

                           totalMarketPrice = totalMarketPrice.toFixed(2) as any

                           const historicalPriceChange =
                              stockFind?.regular_market_price * item.quantity - item.historicalPrice * item.quantity
                           const withoutCalChange = stockFind?.regular_market_price - item.historicalPrice
                           const historicaltotalPriceChangePercent = (withoutCalChange * 100) / item.historicalPrice

                           const historicalTotalPrice =
                              getCurrency(item.historicalPrice, item.stock.currency, diffrentPath) * item.quantity
                           console.log(item.historicalPrice, 'historicalTotalPrice')
                           return (
                              <tr key={item.id}>
                                 <td className="px-2 py-2">
                                    <div className="flex gap-1">
                                       <div className="w-8 h-8">
                                          {item?.stock?.logo && (
                                             <img src={item.stock.logo} className="w-8 h-8 rounded-full" />
                                          )}
                                       </div>
                                       <span className="font-semibold">
                                          {item.stock.short_name} ({item.stock.symbol})
                                       </span>
                                    </div>
                                 </td>
                                 <td className="px-2 py-2 text-right">{item.quantity}</td>

                                 <td className="px-2 py-2 text-right">
                                    <div className="flex fex-col items-end" style={{ flexDirection: 'column' }}>
                                       <span className="font-semibold ">{regularMarketPrice}</span>
                                    </div>
                                 </td>
                                 <td className="px-2 py-2 text-right">
                                    <div className="flex fex-col items-end" style={{ flexDirection: 'column' }}>
                                       <span className="font-semibold ">
                                          {MoneyFormat2(
                                             totalMarketPrice as any,
                                             diffrentPath
                                                ? 'EUR'
                                                : stockFind?.currency == '$'
                                                ? 'USD'
                                                : stockFind?.currency
                                          )}
                                       </span>
                                    </div>
                                 </td>
                                 <td className="px-2 py-2 text-right">
                                    <div
                                       className={`font-semibold flex flex-col items-end ${
                                          stockFind?.regular_market_change > 0 ? 'text-success-600' : 'text-red-500'
                                       }`}
                                    >
                                       <span>
                                          {MoneyFormat2(
                                             getCurrency(
                                                stockFind?.regular_market_change,
                                                stockFind?.currency,
                                                diffrentPath
                                             ),
                                             diffrentPath
                                                ? 'EUR'
                                                : stockFind?.currency == '$'
                                                ? 'USD'
                                                : stockFind?.currency
                                          )}
                                       </span>
                                       <span>{stockFind?.regular_market_change_percent}%</span>
                                    </div>
                                 </td>

                                 <td className="px-2 py-2 text-right">
                                    <div className="flex fex-col items-end" style={{ flexDirection: 'column' }}>
                                       <span className="font-semibold ">
                                          {MoneyFormat2(
                                             getCurrency(item.historicalPrice, item.stock.currency, diffrentPath),
                                             diffrentPath
                                                ? 'EUR'
                                                : stockFind?.currency == '$'
                                                ? 'USD'
                                                : stockFind?.currency
                                          )}
                                       </span>
                                    </div>
                                 </td>
                                 <td className="px-2 py-2 text-right">
                                    <div className="flex fex-col items-end" style={{ flexDirection: 'column' }}>
                                       <span className="font-semibold ">
                                          {
                                             MoneyFormat2(
                                                historicalTotalPrice.toFixed(2),
                                                diffrentPath
                                                   ? 'EUR'
                                                   : stockFind?.currency == '$'
                                                   ? 'USD'
                                                   : stockFind?.currency
                                             ) as any
                                          }
                                       </span>
                                    </div>
                                 </td>
                                 <td className="px-2 py-2 text-right">
                                    <div
                                       className={`font-semibold flex flex-col items-end ${
                                          historicalPriceChange > 0 ? 'text-success-600' : 'text-red-500'
                                       }`}
                                    >
                                       <span>
                                          {MoneyFormat2(
                                             getCurrency(
                                                historicalPriceChange.toFixed(2),
                                                item.stock.currency,
                                                diffrentPath
                                             ),
                                             diffrentPath
                                                ? 'EUR'
                                                : stockFind?.currency == '$'
                                                ? 'USD'
                                                : stockFind?.currency
                                          )}
                                       </span>
                                       <span>{historicaltotalPriceChangePercent.toFixed(2)}%</span>
                                    </div>
                                 </td>
                                 <td className="text-right px-5">{item.created_at}</td>
                              </tr>
                           )
                        }
                     })}
               </tbody>
            </table>
         </div>
      </div>
   )
}
