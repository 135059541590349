import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface UserState {
    user: {},
    token?: string,
    shouldUpdateProfile: boolean
}

const initialState: UserState = {
    user: {},
    token: '',
    shouldUpdateProfile: false
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<UserState>) => {


            state.user = action.payload
        },
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload
        },
        setShouldUpdateProfile: (state, action: PayloadAction<boolean>) => {
            state.shouldUpdateProfile = action.payload
        }

    },
})

// Action creators are generated for each case reducer function
export const { setUser, setShouldUpdateProfile, setToken } = userSlice.actions

export default userSlice.reducer