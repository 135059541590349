import React from "react";
import { useTranslation } from "react-i18next";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
} from "@nextui-org/react";
import {
  IconCircleChevronDown,
  IconCircleChevronUp,
} from "@tabler/icons-react";
import moment from "moment";

interface TableProps {
  data: any[];
}
export default function BuyTable(props: TableProps) {
  const { t } = useTranslation();
  const { data } = props;
  return (
    <div>
      <Table aria-label="Example static collection table">
        <TableHeader className="!hidden" style={{ display:"none" }}>
          <TableColumn className="hidden">NAME</TableColumn>
          <TableColumn className="hidden">ROLE</TableColumn>
          <TableColumn className="hidden">STATUS</TableColumn>
          <TableColumn className="hidden">STATUS</TableColumn>
        </TableHeader>
        <TableBody>
          {data?.map((item: any) => (
            <TableRow key={item.id}>
              <TableCell>
                {item.type == "buy" ? (
                  <IconCircleChevronUp color="green" />
                ) : (
                  <IconCircleChevronDown color="red" />
                )}
              </TableCell>
              <TableCell>
                <div className="flex flex-col">
                  <span className="font-bold text-[15px]">
                    {item.stock.short_name} {'>'} {item.user.name}
                  </span>
                  <span>
                    {moment(item.created_at).format("LL")}
                  </span>
                </div>
              </TableCell>
              <TableCell>
                <div className="flex flex-col">
                  <span className="font-bold text-[15px]">
                    {item.price} {item.stock.currency}
                  </span>
                  <span>
                    {t("common.stockValue")}
                  </span>
                  </div>
              </TableCell>
              <TableCell>
                <div className="flex flex-col">
                  <span className="font-bold text-[15px]">
                    {item.total} {item.stock.currency}
                  </span>
                  <span>
                    {t("common.amountFor")} x{item.quantity}
                  </span>
                  </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
