import { APP_LIST, APP_LIST_URL } from 'api/app'
import AppLayout from 'components/layouts/AppLayout'
import { setApp } from 'features/user/app'
import useFetch from 'hooks/use-fetch'
import Login from 'pages/auth/Login'
import ClientLogin from 'pages/client-login'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

function Wrapper() {
   const [appSettings, { loading: appLoading, error, data }] = useFetch<any>()

   const dispatch = useDispatch()

   useEffect(() => {
      //@ts-ignore
      appSettings(APP_LIST, {
         endPoint: APP_LIST_URL
      })
   }, [])

   useEffect(() => {
      if (data) {
         dispatch(setApp(data?.data))
      }
   }, [data])

   return (
      <div>
         <Helmet>
            <title>{data?.data?.app_name}</title>
         </Helmet>
         <Router>
            <Routes>
               <Route path="/" element={<ClientLogin />} />
               <Route path="/auth/login" element={<Login />} />
               <Route path="/dashboard/*" element={<AppLayout />} />
            </Routes>
         </Router>
      </div>
   )
}

export default Wrapper
