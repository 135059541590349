import React, { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { MoneyFormat, PriceFormat } from 'hooks/currency'
import pusherJs from 'pusher-js'
import { Loader } from 'rizzui'
import cn from 'utils/class-names'

interface TableProps {
   data: any[]
   bottomContent?: any
   topContent?: any
   isLoaded?: boolean
   stockData?: any
}

export default function SellStockTable(props: TableProps) {
   const { t } = useTranslation()
   const columns = [
      {
         name: t('common.stockName'),
         uid: 'stockName',
         className: 'text-left capitalize'
      },
      {
         name: t('common.quantity'),
         uid: 'quantity',
         className: 'flex items-center  capitalize'
      },
      {
         name: t('common.historicalPrice'),
         uid: 'historicalPrice',
         className: 'flex items-center  capitalize'
      },

      {
         name: t('common.totalValue'),
         uid: 'totalValue',
         className: 'flex items-center  capitalize'
      },
      {
         name: t('common.actionDate'),
         uid: 'created_at',
         className: 'flex items-center  capitalize'
      }
   ]

   const getCurrency = (price: string, format: string) => {
      return PriceFormat(price, format)
   }

   const [stockData, setStockData] = React.useState<any>([])

   useEffect(() => {
      const pusher = new pusherJs('614ec570899f6dada256', {
         cluster: 'eu'
      })
      const channel = pusher.subscribe('tablo-guncelleme')

      channel.bind('tablo-guncelleme', function (data: any) {
         setStockData(data.stock)
      })
   }, [])

   const navigate = useNavigate()
   const { data } = props

   return (
      <div>
         <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
               <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                     {columns.map(column => (
                        <th key={column.uid} className={cn('px-2 py-2 font-semibold')}>
                           <div className={cn('text-right', column.className)}>{column.name}</div>
                        </th>
                     ))}
                  </tr>
               </thead>
               <tbody>
                  {props.isLoaded && (
                     <tr>
                        <td colSpan={columns.length}>
                           <div className="flex justify-center items-center h-20">
                              <Loader />
                           </div>
                        </td>
                     </tr>
                  )}
                  {!props.isLoaded && data.length === 0 && (
                     <tr>
                        <td colSpan={columns.length}>
                           <div className="flex justify-center items-center h-20">
                              <span className="text-gray-400 dark:text-gray-600">{t('common.noData')}</span>
                           </div>
                        </td>
                     </tr>
                  )}
                  {data.map(item => {
                     const stockFind = stockData?.find((stock: any) => stock.id === item.stock.id)
                     if (!stockFind) {
                        return null
                     } else {
                        const regularMarketPrice = MoneyFormat(
                           getCurrency(item.savedRegularMarketPrice, stockFind?.currency),
                           'EUR'
                        )
                        const historicalPriceChange = stockFind?.regular_market_price - item.historicalPrice

                        const historicaltotalPriceChangePercent = (historicalPriceChange * 100) / item.historicalPrice
                        return (
                           <tr key={item.id}>
                              <td className="px-2 py-2">
                                 <div className="flex gap-1">
                                    <div className="w-8 h-8">
                                       {item?.stock?.logo && (
                                          <img src={item.stock.logo} className="w-8 h-8 rounded-full" />
                                       )}
                                    </div>
                                    <span className="font-semibold">
                                       {item.stock.short_name} ({item.stock.symbol})
                                    </span>
                                 </div>
                              </td>
                              <td className="px-2 py-2">{item.quantity}</td>
                              <td className="px-2 py-2">
                                 <div className="flex fex-col" style={{ flexDirection: 'column' }}>
                                    <span className="font-semibold ">
                                       {MoneyFormat(getCurrency(item.historicalPrice, item.stock.currency))}
                                    </span>
                                    <span
                                       className="font-semibold text-[12px] none"
                                       style={{
                                          display: 'none'
                                       }}
                                    >
                                       {MoneyFormat(item.historicalTotalPrice, item.stock.currency)}
                                    </span>
                                 </div>
                              </td>

                              <td className="px-2 py-2">
                                 <div className="flex fex-col" style={{ flexDirection: 'column' }}>
                                    <span className="font-semibold ">
                                       {MoneyFormat(getCurrency(item.totalValue, item.stock.currency))}
                                    </span>
                                    <span
                                       className="font-semibold text-[12px] hidden"
                                       style={{
                                          display: 'none'
                                       }}
                                    >
                                       {item.totalValue} {item.stock.currency}
                                    </span>
                                 </div>
                              </td>
                              <td>{item.created_at}</td>
                           </tr>
                        )
                     }
                  })}
               </tbody>
            </table>
         </div>
      </div>
   )
}
