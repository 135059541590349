import axios from "axios";
import { FetchProps } from "./interface";

export const LOGIN_URL = '/auth/login';

export const LOGIN = async (props:FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .post(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            console.error("hata",error);
            return { error };
        });
};