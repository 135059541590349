import {
  CheckboxGroup,
  Radio,
  RadioGroup,
  SelectItem,
} from "@nextui-org/react";
import { Form, Select } from "antd";
import { USER_LIST } from "api/user";
import ReactSelect from "components/Select";
import { MoneyFormat } from "hooks/currency";
import useFetch from "hooks/use-fetch";
import React, { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { Input } from "rizzui";

interface Props {
  onFinish: any;
  initialValues?: any;
  isLoading?: boolean;
  form: any;
}
export default function IposForm(props: Props) {
  const { t } = useTranslation();
  const [
    usersQuery,
    { data: usersData, loading: usersLoading, error: usersError },
  ] = useFetch<any>();

  const [page, setPage] = useState(1);

  useEffect(() => {
    //@ts-ignore
    usersQuery(USER_LIST, {
      endPoint: "users-all",
    });
  }, []);

  useEffect(() => {
    props.form.setFieldValue("user_id", props.initialValues?.user_id);
    props.form.setFieldValue("name", props.initialValues?.name);
    props.form.setFieldValue("description", props.initialValues?.description);
    props.form.setFieldValue("amount", props.initialValues?.amount);
    props.form.setFieldValue("period", props.initialValues?.period);
    props.form.setFieldValue("wkn", props.initialValues?.wkn);
    props.form.setFieldValue("rate", props.initialValues?.rate);
    props.form.setFieldValue("period", props.initialValues?.period);
    props.form.setFieldValue("start_date", props.initialValues?.start_date);
    props.form.setFieldValue("payment_plan_type", props.initialValues?.payment_plan_type);
  }, [props.initialValues]);

  return (
    <div>
      <Form.Item
        rules={[
          {
            required: true,
            message: t("validation.user_required"),
          },
        ]}
        label={t("common.user")}
        name="user_id"
      >
        <Select
          loading={usersLoading}
          defaultValue={props.initialValues?.user_id}
        >
          {usersData?.data?.map((user: any) => (
            <Select.Option value={user.id}>
              {user.name + " " + user.last_name} - {MoneyFormat(user.balance)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        rules={[
          {
            required: true,
            message: t("validation.name_required"),
          },
        ]}
        label={t("common.name")}
        name="name"
      >
        <Input
          value={props.initialValues?.name}
          placeholder={t("common.name")}
          className="ant-input rizzui-input"
        />
      </Form.Item>

      <Form.Item
        rules={[
          {
            required: true,
            message: t("validation.amount_required"),
          },
        ]}
        label={t("common.amount")}
        name="amount"
      >
        <NumericFormat
          placeholder="0.00"
          className="ant-input rizzui-input"
          prefix="€"
          decimalSeparator="."
          thousandSeparator=","
          customInput={Input}
          value={props.initialValues?.amount}
          onValueChange={(value: any) => {
            console.log(value.floatValue);
            props.form.setFieldValue("amount", value.floatValue);
          }}
        />
        <input type="hidden" name="amount" />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: t("validation.rate"),
          },
        ]}
        label={t("common.rate")}
        name="rate"
      >
        <Input
          value={props.initialValues?.rate}
          placeholder={t("common.rate")}
          className="ant-input rizzui-input"
        />
      </Form.Item>
      {/* <Form.Item
        rules={[
          {
            required: true,
            message: t("validation.description"),
          },
        ]}
        label={t("common.description")}
        name="description"
      >
        <Input
          value={props.initialValues?.description}
          placeholder={t("common.description")}
          className="ant-input rizzui-input"
        />
      </Form.Item> */}
      <Form.Item
        rules={[
          {
            required: true,
            message: t("validation.start_date"),
          },
        ]}
        label={t("common.startDate")}
        name="start_date"
      >
        <Input
          type="date"
          value={props.initialValues?.start_date}
          placeholder={t("common.startDate")}
          className="ant-input rizzui-input"
        />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: t("validation.period"),
          },
        ]}
        label={t("common.period")}
        name="period"
      >
        <Select placeholder="Select a option and change input text above">
          <Select.Option value="3">
            {t("common.three_month")}
          </Select.Option>
          <Select.Option value="6">
            {t("common.six_month")}
          </Select.Option>
          <Select.Option value="12">
            {t("common.twelve_month")}
          </Select.Option>
          <Select.Option value="24">
            {t("common.twenty_four_month")}
          </Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        rules={[
          {
            required: true,
            message: t("validation.payment_plan_type_required"),
          },
        ]}
        label={t("common.paymentPlanType")}
        name="payment_plan_type"
      >
        <Select placeholder="Select a option and change input text above">
          <Select.Option value="1">
            {t("common.weekly")}
          </Select.Option>
          <Select.Option value="2">
            {t("common.monthly")}
          </Select.Option>
          <Select.Option value="3">
            {t("common.four_month")}
          </Select.Option>
          <Select.Option value="4">
            {t("common.six_month")}
          </Select.Option>
          <Select.Option value="5">
            {t("common.twelve_month")}
          </Select.Option>
        </Select>
      </Form.Item>
    </div>
  );
}
