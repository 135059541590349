import { Radio, RadioGroup, SelectItem } from '@nextui-org/react'
import { Form, Select } from 'antd'
import { USER_LIST } from 'api/user'
import ReactSelect from 'components/Select'
import { MoneyFormat } from 'hooks/currency'
import useFetch from 'hooks/use-fetch'
import React, { useEffect, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { Input } from 'rizzui'

interface Props {
   onFinish: any
   initialValues?: any
   isLoading?: boolean
   form: any
}
export default function ReferralsForm(props: Props) {
   const { t } = useTranslation()
   const [usersQuery, { data: usersData, loading: usersLoading, error: usersError }] = useFetch<any>()

   const [page, setPage] = useState(1)

   useEffect(() => {
      //@ts-ignore
      usersQuery(USER_LIST, {
         endPoint: 'users-all'
      })
   }, [])

   useEffect(() => {
      props.form.setFieldValue('user_id', props.initialValues?.user_id)
      props.form.setFieldValue('type', props.initialValues?.type)
      props.form.setFieldValue('method', props.initialValues?.method)
      props.form.setFieldValue('amount', props.initialValues?.amount)
      props.form.setFieldValue('description', props.initialValues?.description)
      props.form.setFieldValue('note', props.initialValues?.note)
   }, [props.initialValues])

   return (
      <div>
         <Form.Item
            rules={[
               {
                  required: true,
                  message: t('validation.user_required')
               }
            ]}
            label={t('common.user')}
            name="user_id"
         >
            <Select loading={usersLoading} defaultValue={props.initialValues?.user_id}>
               {usersData?.data?.map((user: any) => (
                  <Select.Option value={user.id}>
                     {user.name + " " + user.last_name} - {MoneyFormat(user.balance)}
                  </Select.Option>
               ))}
            </Select>
         </Form.Item>
         <Form.Item
            rules={[
               {
                  required: true,
                  message: t('validation.transaction_type_required')
               }
            ]}
            label={t('common.transactionType')}
            name="type"
            valuePropName="checked"
         >
            <RadioGroup
               name="type"
               defaultValue={props.initialValues?.type}
               onChange={e => {
                  props.form.setFieldValue('type', e.target.value)
               }}
            >
               <Radio value="bonus">Bonus</Radio>
               <Radio value="system">System</Radio>
            </RadioGroup>
         </Form.Item>
         {/* <Form.Item
        rules={[
          {
            required: true,
            message: t("validation.method_required"),
          },
        ]}
        label={t("common.method")}
        name="method"
        valuePropName="checked"
      >
        <RadioGroup name="method" defaultValue={props.initialValues?.method} onChange={(e) => {
            props.form.setFieldValue("method", e.target.value);
        }}>
          <Radio value="manual">Manual</Radio>
          <Radio value="system">System</Radio>
        </RadioGroup>
      </Form.Item> */}
         <Form.Item
            rules={[
               {
                  required: true,
                  message: t('validation.amount_required')
               }
            ]}
            label={t('common.amount')}
            name="amount"
         >
            <NumericFormat
               placeholder="0.00"
               className="ant-input rizzui-input"
               prefix="€"
               decimalSeparator="."
               thousandSeparator=","
               customInput={Input}
               value={props.initialValues?.amount}
               onValueChange={(value: any) => {
                  console.log(value.floatValue)
                  props.form.setFieldValue('amount', value.floatValue)
               }}
            />
            <input type="hidden" name="amount" />
         </Form.Item>
         <Form.Item
            rules={[
               {
                  required: false,
                  message: t('validation.description')
               }
            ]}
            label={t('common.description')}
            name="description"
         >
            <Input
               value={props.initialValues?.description}
               placeholder={t('common.description')}
               className="ant-input rizzui-input"
            />
         </Form.Item>
         {/* <Form.Item
        rules={[
          {
            required: false,
            message: t("validation.note"),
          },
        ]}
        label={t("common.note")}
        name="note"
      >
        <Input
          value={props.initialValues?.note}
          placeholder={t("common.note")}
          className="ant-input rizzui-input"
        />
      </Form.Item> */}
      </div>
   )
}
