'use client'

import { Popover } from 'components/ui/popover'
import { Title, Text } from 'components/ui/text'
import { routes } from 'config/routes'
import cn from 'utils/class-names'
import { signOut } from 'next-auth/react'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_USER, getAuth } from 'gql/user'
import { Avatar, Divider, Form, Input, message } from 'antd'
import { PiUser } from 'react-icons/pi'
import { useTranslation } from 'react-i18next'
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from '@nextui-org/react'
import { Alert } from 'rizzui'
import useFetch from 'hooks/use-fetch'
import { USER_UPDATE } from 'api/user'
import { useDispatch } from 'react-redux'
import { setShouldUpdateProfile } from 'features/user/user'

const menuItems = [
   {
      name: 'My Profile',
      href: routes.home
   },
   {
      name: 'Account Settings',
      href: routes.home
   },
   {
      name: 'Activity Log',
      href: '#'
   }
]

function DropdownMenu() {
   const dispatch = useDispatch()
   const user = JSON.parse(localStorage.getItem('user') || '{}')
   const { t } = useTranslation()
   const { isOpen, onOpen, onClose } = useDisclosure()

   const [messageApi, contextHolder] = message.useMessage()

   const key = 'updatable'

   const [updateProfile, { data, loading, error }] = useFetch<any>()

   const [form] = Form.useForm()

   const handleUpdate = async (values: any) => {
      //@ts-ignore
      updateProfile(USER_UPDATE, {
         endPoint: 'auth/updateProfile',
         body: values
      })
   }

   useEffect(() => {
      if (loading) {
         messageApi.open({
            type: 'loading',
            content: t('common.updateProfileLoading'),
            duration: 2
         })
      }
   }, [loading])

   useEffect(() => {
      if (data) {
         if (data.status) {
            messageApi.open({
               type: 'success',
               content: t('common.updateProfileSuccess'),
               duration: 2
            })
         } else {
            messageApi.open({
               type: 'error',
               content: t('common.updateProfileError'),
               duration: 2
            })
         }
      }
   }, [data])

   useEffect(() => {
      console.log(user)
      form.setFieldsValue({
         name: user?.name,
         email: user?.email,
         last_name: user?.lastname,
         country: user?.country,
         city: user?.city,
         street: user?.street,
         postal_code: user?.postal_code,
         phone_number: user?.phone_number,
         mobile_number: user?.mobile_number,
         date_of_birth: user?.date_of_birth
      })
   }, [user])

   return (
      <div>
         {contextHolder}


         <div className="w-64 text-left rtl:text-right">
            <div className="flex items-center border-b border-gray-300 px-6 pb-5 pt-6">
               <Avatar>{user.name[0]}</Avatar>
               <div className="ms-3">
                  <Title as="h6" className="font-semibold">
                     {user.name}
                  </Title>
                  <Text className="text-gray-600">{user.email}</Text>
               </div>
            </div>
            <div className="grid px-3.5 py-3.5 font-medium text-gray-700">
               <span
                  onClick={() => {
                     dispatch(setShouldUpdateProfile(true))
                  }}
                  className="cursor-pointer group my-0.5 flex items-center rounded-md px-2.5 py-2 hover:bg-gray-100 focus:outline-none hover:dark:bg-gray-50/50"
                  data-tabindex=""
               >
                  {t('common.updateProfile')}
               </span>

               {/* <Link
                  to={routes.activities}
                  className="group my-0.5 flex items-center rounded-md px-2.5 py-2 hover:bg-gray-100 focus:outline-none hover:dark:bg-gray-50/50"
                  data-tabindex=""
               >
                  {t('aside.activities')}
               </Link> */}
            </div>

            <div className="border-t border-gray-300 px-6 pb-6 pt-5">
               <Button
                  variant="faded"
                  onClick={() => {
                     localStorage.clear()
                     window.location.href = '/'
                  }}
               >
                  {t('common.logout')}
               </Button>
            </div>
         </div>
      </div>
   )
}

export default function ProfileMenu({
   buttonClassName,
   avatarClassName
}: {
   buttonClassName?: string
   avatarClassName?: string
}) {
   const [isOpen, setIsOpen] = useState(false)
   const pathname = useLocation()

   useEffect(() => {
      setIsOpen(false)
   }, [pathname])

   return (
      <Popover
         isOpen={isOpen}
         setIsOpen={setIsOpen}
         content={() => <DropdownMenu />}
         shadow="sm"
         placement="bottom-end"
         className="z-50 p-0 dark:bg-gray-100 [&>svg]:dark:fill-gray-100"
      >
         <button
            className={cn(
               'w-9 shrink-0 rounded-full outline-none focus-visible:ring-[1.5px] focus-visible:ring-gray-400 focus-visible:ring-offset-2 active:translate-y-px sm:w-10',
               buttonClassName
            )}
         >
            <Avatar className={avatarClassName + ' bg-primary flex items-center justify-center'}>
               <PiUser className="h-auto w-[20px]" />
            </Avatar>
         </button>
      </Popover>
   )
}
