import { Button, Chip, Card, CardHeader, CardBody } from '@nextui-org/react'
import { Form, Modal, Popconfirm, Table, TableColumnProps, message } from 'antd'
import {
   INVESTED_DELETE,
   INVESTED_LIST,
   INVESTED_LIST_URL,
   INVESTED_STORE,
   INVESTED_STORE_URL,
   INVESTED_UPDATE,
   INVESTED_UPDATE_URL
} from 'api/invested'
import PageHeader from 'components/page-header'
import { routes } from 'config/routes'
import { MoneyFormat, MoneyFormat2 } from 'hooks/currency'
import useFetch from 'hooks/use-fetch'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import InvestedForm from './Form'
import ActionButton from './ActionButton'
import cn from 'utils/class-names'
import TableComponents from 'components/Table'
import UserFilter from 'components/FilterContainer/User'
import SearchInput from 'components/Input'
import DateRange from 'components/FilterContainer/DateRange'
import { useNavigate } from 'react-router-dom'

export default function Invested() {
   const { t } = useTranslation()
   const navigate = useNavigate()
   const pageHeader = {
      title: t('aside.invested'),
      breadcrumb: [
         {
            href: routes.dashboard,
            name: t('aside.dashboard')
         },
         {
            href: routes.invested.list,
            name: t('aside.invested')
         }
      ]
   }

   const [page, setPage] = React.useState(1)
   const [row, setRow] = React.useState<any>({}) // row selection
   const [addModal, setAddModal] = React.useState(false)
   const [editModal, setEditModal] = React.useState(false)
   const [search, setSearch] = React.useState('')
   const [selectedUser, setSelectedUser] = React.useState<any>(null)
   const [startDate, setStartDate] = React.useState<any>(null)
   const [endDate, setEndDate] = React.useState<any>(null)

   const addOnOpenModal = () => setAddModal(true)
   const addOnCloseModal = () => setAddModal(false)
   const addOpenModal = addModal
   const editOnOpenModal = () => setEditModal(true)
   const editOnCloseModal = () => setEditModal(false)
   const editOpenModal = editModal

   const [referralsQuery, { data, loading }] = useFetch<any>()

   const [addInvestedMutation, { data: addInvestedData, loading: addInvestedLoading }] = useFetch<any>()

   const [editInvestedMutation, { data: editInvestedData, loading: editInvestedLoading }] = useFetch<any>()

   const [deleteInvestedMutation, { data: deleteInvestedData, loading: deleteInvestedLoading }] = useFetch<any>()

   useEffect(() => {
      //@ts-ignore
      referralsQuery(INVESTED_LIST, {
         endPoint: INVESTED_LIST_URL.replace(':page', page.toString())
      })
   }, [page])

   const columns: TableColumnProps<any>[] = [
      {
         title: 'ID',
         dataIndex: 'id',
         key: 'id'
      },
      {
         title: t('common.user'),
         dataIndex: 'user',
         key: 'user',
         render: (user: any) => {
            return (
               <div className="flex items-center gap-3">
                  {user.name} {user.last_name}
               </div>
            )
         }
      },
      {
         title: t('common.name'),
         dataIndex: 'user',
         key: 'user',
         render: (user: any) => {
            return <div className="flex items-center gap-3">{user.name}</div>
         }
      },

      {
         title: t('common.amount'),
         dataIndex: 'amount',
         key: 'amount',
         render: (amount: any) => {
            return <div className="flex items-center gap-3">{MoneyFormat2(amount)}</div>
         }
      },
      {
         title: t('common.startDate'),
         dataIndex: 'start_date',
         key: 'start_date',
         render: (start_date: any) => {
            return <div className="flex items-center gap-3">{moment(start_date).format('DD.MM.YYYY')}</div>
         }
      },
      {
         title: t('common.endDate'),
         dataIndex: 'end_date',
         key: 'end_date',
         render: (end_date: any) => {
            return <div className="flex items-center gap-3">{moment(end_date).format('DD.MM.YYYY')}</div>
         }
      },
      {
         title: t('common.period'),
         dataIndex: 'period',
         key: 'period',
         render: (period: any) => {
            return <div className="flex items-center gap-3">{period} month</div>
         }
      },
      {
         title: t('common.rate'),
         dataIndex: 'rate',
         key: 'rate',
         render: (rate: any) => {
            return <div className="flex items-center gap-3">{rate.toString().replace('.', ',')}%</div>
         }
      },
      {
         title: t('common.total'),
         dataIndex: 'period',
         key: 'period',
         render: (period: any, record: any) => {
            return <div className="flex items-center gap-3">{MoneyFormat2((record.rate * record.amount) / 100)}</div>
         }
      },

      {
         title: t('common.actions'),
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  <ActionButton
                     deleteButtonClick={() => {
                        //@ts-ignore
                        Modal.confirm({
                           title: t('messages.delete_confirm'),
                           okText: t('buttons.delete'),
                           okType: 'danger',
                           cancelText: t('buttons.cancel'),
                           onOk() {
                              //@ts-ignore
                              deleteInvestedMutation(INVESTED_DELETE, {
                                 endPoint: INVESTED_UPDATE_URL + '/' + id
                              })
                           }
                        })
                     }}
                     editButtonClick={() => {
                        setRow(record)
                        editOnOpenModal()
                     }}
                  />
               </div>
            )
         }
      },
      {
         title: t('common.paymentPlan'),
         dataIndex: 'id',
         key: 'id',
         render: (id: any) => {
            return (
               <Button color="primary" variant="solid" onClick={() => {
                  navigate(routes.paymentPlan(id))
               }}>
                  {t('common.paymentPlan')}
               </Button>
            )
         }
      }
   ]

   const [addForm] = Form.useForm()

   const [editForm] = Form.useForm()

   const onFilter = () => {
      let object: any = {}
      if (selectedUser) {
         object.user_id = selectedUser
      }

      if (search) {
         object.search = search
      }

      if (startDate) {
         object.start_date = startDate
      }

      if (endDate) {
         object.end_date = endDate
      }

      let query = new URLSearchParams(object).toString()

      //@ts-ignore
      referralsQuery(INVESTED_LIST, {
         endPoint: INVESTED_LIST_URL.replace(':page', (1).toString() + `&${query}`)
      })
   }

   const onAddFinish = (values: any) => {
      //@ts-ignore
      addInvestedMutation(INVESTED_STORE, {
         endPoint: INVESTED_STORE_URL,
         body: values
      })
   }

   const onUpdateFinish = (values: any) => {
      //@ts-ignore
      editInvestedMutation(INVESTED_UPDATE, {
         endPoint: INVESTED_UPDATE_URL + '/' + row.id,
         body: values
      })
   }

   const onDeleteFinish = (values: any) => {
      //@ts-ignore
      deleteInvestedMutation(INVESTED_DELETE, {
         endPoint: INVESTED_UPDATE_URL + '/' + row.id,
         body: values
      })
   }

   useEffect(() => {
      if (addInvestedData) {
         if (addInvestedData?.status) {
            message.success(t('messages.add_success'))
            addOnCloseModal()
            //@ts-ignore
            referralsQuery(INVESTED_LIST, {
               endPoint: INVESTED_LIST_URL.replace(':page', page.toString())
            })
            addForm.resetFields()
         }
         else {
            message.error(t('messages.' + addInvestedData?.message || 'error'))
         }
      }
   }, [addInvestedData])

   useEffect(() => {
      if (editInvestedData) {
         message.success(t('messages.update_success'))
         editOnCloseModal()
         //@ts-ignore
         referralsQuery(INVESTED_LIST, {
            endPoint: INVESTED_LIST_URL.replace(':page', page.toString())
         })
         editForm.resetFields()
      }
   }, [editInvestedData])

   useEffect(() => {
      if (deleteInvestedData) {
         message.success(t('messages.delete_success'))
         //@ts-ignore
         referralsQuery(INVESTED_LIST, {
            endPoint: INVESTED_LIST_URL.replace(':page', page.toString())
         })
      }
   }, [deleteInvestedData])

   return (
      <div>
         <Modal title={t('buttons.add')} open={addOpenModal} onCancel={addOnCloseModal} footer={null}>
            <Form form={addForm} onFinish={onAddFinish} layout="vertical">
               <InvestedForm form={addForm} isLoading={addInvestedLoading} onFinish={onAddFinish} />
               <div className="flex gap-5 items-end">
                  <Button color="danger" variant="light" onPress={addOnCloseModal}>
                     {t('buttons.cancel')}
                  </Button>
                  <Button color="primary" variant="light" type="submit" isLoading={addInvestedLoading}>
                     {t('buttons.add')}
                  </Button>
               </div>
            </Form>
         </Modal>
         <Modal title={t('invested.edit.title')} open={editOpenModal} onCancel={editOnCloseModal} footer={null}>
            <Form form={editForm} onFinish={onUpdateFinish} layout="vertical">
               <InvestedForm
                  form={editForm}
                  isLoading={editInvestedLoading}
                  onFinish={onUpdateFinish}
                  initialValues={row}
               />
               <div className="flex gap-5 items-end">
                  <Button color="danger" variant="light" onPress={editOnCloseModal}>
                     {t('buttons.cancel')}
                  </Button>
                  <Button color="primary" variant="light" type="submit" isLoading={editInvestedLoading}>
                     {t('buttons.save')}
                  </Button>
               </div>
            </Form>
         </Modal>

         <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
            <Button color="primary" onClick={addOnOpenModal}>
               {t('buttons.addInvest')}
            </Button>
         </PageHeader>
         <div className="@container">
            <Card title="Filter" className="mb-5">
               <CardHeader>
                  <h3 className="text-lg font-semibold">{t('common.filterTable')}</h3>
               </CardHeader>
               <CardBody className="grid grid-cols-4 gap-2 items-center">
                  <UserFilter onChange={(e: any) => setSelectedUser(e.value)} value={selectedUser} />
                  <SearchInput onChange={(e: any) => setSearch(e.target.value)} value={search} />
                  <DateRange
                     onChangeOne={(e: any) => setStartDate(e.target.value)}
                     onChangeTwo={(e: any) => setEndDate(e.target.value)}
                     valueOne={startDate}
                     valueTwo={endDate}
                     hasOne={true}
                     hasTwo={true}
                  />
                  <Button color="primary" variant="solid" onClick={() => onFilter()} className="mt-5">
                     {t('common.filter')}
                  </Button>
               </CardBody>
            </Card>
            <Card title={t('aside.invested')}>
               <TableComponents
                  columns={columns}
                  data={data?.data?.data || []}
                  pagination={{
                     total: data?.data?.total,
                     current: page,
                     pageSize: 10,
                     onChange: (page: any) => setPage(page)
                  }}
                  loading={loading}
               />
            </Card>
         </div>
      </div>
   )
}
