import {
   IconAppWindow,
   IconCreditCard,
   IconHome,
   IconHtml,
   IconNotification,
   IconPackageExport,
   IconUserExclamation,
   IconWebhook,
   IconUsers,
   IconChartArea,
   IconDiscountCheck,
   IconList,
   IconChecklist,
   IconClock,
   IconLanguage,
   IconSettings
} from '@tabler/icons-react'
import { routes } from 'config/routes'

// Note: do not add href in the label object, it is rendering as label
export const menuItems = [
   // label start
   {
      name: 'aside.dashboard',
      href: '/dashboard',
      // href: routes.file.dashboard,
      icon: <IconHome />
   },
   // {
   //    name: 'aside.myStocks',
   //    href: routes.myStocks,
   //    // href: routes.file.dashboard,
   //    icon: <IconDiscountCheck />
   // },
   {
      name: 'aside.liveTrading',
      href: routes.liveTrading,
      icon: <IconChartArea />
   },
   {
      name: 'aside.myPortfolio',
      href: '#',
      icon: <IconChartArea />,
      dropdownItems: [
         {
            name: 'aside.myStocks',
            href: routes.myStocks
         },
         {
            name: 'aside.selledStocks',
            href: routes.transcationSell
         }
      ]
   },

   {
      name: 'aside.ipos',

      href: routes.ipos.list,
      icon: <IconList />
   },
   {
      name: 'aside.invested',

      href: routes.invested.list,
      icon: <IconList />
   },
   {
      name: 'aside.referral',
      href: routes.referrals.list,
      // href: routes.file.dashboard,
      icon: <IconList />
   },
   {
      name: 'aside.users',
      href: routes.users.list,
      // href: routes.file.dashboard,
      icon: <IconUsers />
   },
   {
      name: 'aside.language',
      href: routes.language.list,
      // href: routes.file.dashboard,
      icon: <IconLanguage />
   },
   {
      name: 'aside.app_settings',
      href: routes.appSettings,
      // href: routes.file.dashboard,
      icon: <IconSettings />
   }
]

export const userMenuItems = [
   {
      name: 'aside.dashboard',
      href: routes.index,
      // href: routes.file.dashboard,
      icon: <IconHome />
   },

   {
      name: 'aside.liveTrading',
      href: routes.liveTrading,
      icon: <IconChartArea />
   },

   {
      name: 'aside.myPortfolio',
      href: '#',
      icon: <IconChartArea />,
      dropdownItems: [
         {
            name: 'aside.myStocks',
            href: routes.myStocks,
            // href: routes.file.dashboard,
            icon: <IconDiscountCheck />
         },
         //  {
         //     name: 'Without Currency',
         //    href: '/dashboard/my-stocks/without-currency',
         // href: routes.file.dashboard,
         //   icon: <IconDiscountCheck />
         // },
         // {
         //   name: "aside.transactionBuy",
         //   href: routes.transcationBuy,
         // },
         {
            name: 'aside.selledStocks',
            href: routes.transcationSell
         }
      ]
   },


   {
      name: 'aside.plans',
      href: routes.myIpos,
      // href: routes.file.dashboard,
      icon: <IconChecklist />
   },
   {
      name: 'aside.invested',

      href: routes.myInvested,
      icon: <IconList />
   },
   {
      name: 'aside.myReferrals',

      href: routes.myReferrals,
      icon: <IconList />
   },
   // {
   //    name: 'aside.activities',
   //    href: routes.activities,
   //    icon: <IconClock />
   // }
]
