import { Button, Card, User } from '@nextui-org/react'
import { Form, Modal, Popconfirm, Table, TableColumnProps, message } from 'antd'
import { IPOS_LIST, USER_IPOS_LIST_URL } from 'api/ipos'
import PageHeader from 'components/page-header'
import { routes } from 'config/routes'
import { MoneyFormat } from 'hooks/currency'
import useFetch from 'hooks/use-fetch'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import IposForm from './Form'

export default function UserIpos() {
   const { t } = useTranslation()
   const pageHeader = {
      title: t('aside.ipos'),
      breadcrumb: [
         {
            href: routes.dashboard,
            name: t('aside.dashboard')
         },
         {
            href: routes.ipos.list,
            name: t('aside.ipos')
         }
      ]
   }

   const [page, setPage] = React.useState(1)

   const [referralsQuery, { data, loading }] = useFetch<any>()

   useEffect(() => {
      //@ts-ignore
      referralsQuery(IPOS_LIST, {
         endPoint: USER_IPOS_LIST_URL.replace(':page', page.toString())
      })
   }, [page])

   const columns: TableColumnProps<any>[] = [
      {
         title: 'ID',
         dataIndex: 'id',
         key: 'id'
      },
      {
         title: t('common.name'),
         dataIndex: 'name',
         key: 'name',
         render: (name: any) => {
            return (
               <div className="flex items-center gap-3">
                  <span color="primary">{name}</span>
               </div>
            )
         }
      },
      {
         title: t('common.wkn'),
         dataIndex: 'wkn',
         key: 'wkn',
         render: (wkn: any) => {
            return (
               <div className="flex items-center gap-3">
                  <span color="primary">{wkn}</span>
               </div>
            )
         }
      },
      {
         title: t('common.pieces'),
         dataIndex: 'pieces',
         key: 'pieces',
         render: (pieces: any) => {
            return (
               <div className="flex items-center gap-3">
                  <span color="primary">{pieces}</span>
               </div>
            )
         }
      },
      {
         title: t('common.price'),
         dataIndex: 'price',
         key: 'price',
         render: (price: any) => {
            return (
               <div className="flex items-center gap-3">
                  <span color="primary">{MoneyFormat(price)}</span>
               </div>
            )
         }
      },
      {
         title: t('common.total'),
         dataIndex: 'price',
         key: 'price',
         render: (price: any, record) => {
            return (
               <div className="flex items-center gap-3">
                  <span color="primary">{MoneyFormat(price * record.pieces)}</span>
               </div>
            )
         }
      },
      {
         title: t('common.contactDate'),
         dataIndex: 'contact_date',
         key: 'contact_date',
         render: (contact_date: any) => {
            return (
               <div className="flex items-center gap-3">
                  <span color="primary">{moment(contact_date).format('DD.MM.YYYY')}</span>
               </div>
            )
         }
      }
   ]

   return (
      <div>
         <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
            <div className="mt-4 flex items-center gap-3 @lg:mt-0"></div>
         </PageHeader>
         <div className="@container">
            <Card title={t('aside.ipos')}>
               <Table
                  columns={columns}
                  dataSource={data?.data?.data}
                  rowKey="id"
                  pagination={false}
                  loading={loading}
               />
            </Card>
         </div>
      </div>
   )
}
