import React, { useEffect } from 'react'
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Button } from '@nextui-org/react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

export default function App() {
   const { t, i18n } = useTranslation()
   const [selectedKeys, setSelectedKeys] = React.useState(localStorage.getItem('locale') ?? 'en')

   const [locales, setLocales] = React.useState<any>([])

   const getLoceles = async () => {
      const { data } = await axios.get('/locales-all')

      setLocales(data.data)
   }

   useEffect(() => {
      console.log(i18n.language)
      getLoceles()
   }, [])

   useEffect(() => {
      localStorage.setItem('locale', selectedKeys)
      i18n.changeLanguage(selectedKeys)
   }, [selectedKeys])

   useEffect(() => {
      if (i18n.language) {
         setSelectedKeys(i18n.language)
      }
   }, [i18n.language])

   let currentFlat = i18n.language == 'en' ? 'us' : i18n.language

   return (
      <Dropdown>
         <DropdownTrigger>
            <Button variant="faded" className="uppercase">
               <img src={'/flags/' + currentFlat + '.png'} alt={i18n.language} className="w-5 h-5 rounded-full" />
               <span className="t">{i18n.language}</span>
            </Button>
         </DropdownTrigger>
         <DropdownMenu
            aria-label="Multiple selection example"
            variant="flat"
            closeOnSelect={true}
            disallowEmptySelection
            selectionMode="single"
            selectedKeys={i18n.language}
            onSelectionChange={(keys: any) => {
               setSelectedKeys(keys)
               console.log(keys)
            }}
         >
            {locales.map((locale: any) => {
               let flag = locale.code == 'en' ? 'us' : locale.code
               return (
                  <DropdownItem key={locale.code} value={locale.code}>
                     <div className="flex items-center gap-2">
                        <img src={'/flags/' + flag + '.png'} alt={locale.name} className="w-5 h-5 rounded-full" />
                        <span className="t">{locale.locale}</span>
                     </div>
                  </DropdownItem>
               )
            })}
         </DropdownMenu>
      </Dropdown>
   )
}
