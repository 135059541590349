'use client'

import { routes } from 'config/routes'

import { useEffect, useMemo, useState } from 'react'
import PageHeader from 'components/page-header'

import ListTable from './Table'
import { Form } from 'antd'
import { Button, Card, Pagination } from '@nextui-org/react'
import { Input } from '../../../components/ui/input'
import { Filter } from './Filter'
import { useTranslation } from 'react-i18next'
import useFetch from 'hooks/use-fetch'
import { USER_STOCK_LIST, USER_STOCK_LIST_URL } from 'api/userStock'
import { Link } from 'react-router-dom'
import StatCards from '../home/Summary'

export default function MyStocksTable() {
   const { t } = useTranslation()
   const pageHeader = {
      title: t('aside.myStocks'),
      breadcrumb: [
         {
            href: routes.dashboard,
            name: t('aside.dashboard')
         },
         {
            href: routes.myStocks,
            name: t('aside.myStocks')
         }
      ]
   }
   const [page, setPage] = useState(1)
   const [search, setSearch] = useState('')
   const [status, setStatus] = useState('')
   const [is_anonymous, setIs_anonymous] = useState<any>(null)
   const [complaintType, setComplaintType] = useState('')
   const [rows, { data: rowsData, loading: rowsLoading, error: rowsError }] = useFetch<any>()

   useEffect(() => {
      //@ts-ignore
      rows(USER_STOCK_LIST, {
         endPoint: USER_STOCK_LIST_URL.replace(':page', page.toString())
      })
   }, [page, search])

   return (
      <>
         <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
            <div className="mt-4 flex items-center gap-3 @lg:mt-0">
               <Button color="primary" size="lg">
                  <Link to={routes.liveTrading}>{t('common.buyStock')}</Link>
               </Button>
            </div>
         </PageHeader>
         <StatCards />

         <Card className="mt-5">
            <ListTable
               isLoaded={rowsLoading}
               bottomContent={
                  <Pagination
                     total={rowsData?.links?.total || 0}
                     onChange={page => setPage(page)}
                     page={page}
                     color={'primary'}
                  />
               }
               topContent={
                  <Filter
                     setSearch={setSearch}
                     search={search}
                     status={status}
                     setStatus={setStatus}
                     is_anonymous={is_anonymous}
                     setIs_anonymous={setIs_anonymous}
                     complaintType={complaintType}
                     setComplaintType={setComplaintType}
                  />
               }
               data={rowsData?.data?.data || []}
            />
         </Card>
      </>
   )
}
