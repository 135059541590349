'use client'

import { useEffect, useState } from 'react'
import { PiArrowRightBold } from 'react-icons/pi'
import { Checkbox } from 'components/ui/checkbox'
import { Password } from 'components/ui/password'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { routes } from 'config/routes'
import { Form, message } from 'antd'
import { useTranslation } from 'react-i18next'
import useFetch from 'hooks/use-fetch'
import { LOGIN, LOGIN_URL } from 'api/auth'
import { Alert } from 'rizzui'
import { Link } from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";


export default function SignInForm({ logo, mail }: any) {
   const { t } = useTranslation()
   //TODO: why we need to reset it here
   const [loginMutation, { data: loginData, loading: loginLoading, error: loginError }] = useFetch<any>()
   const [isVerified, setIsVerified] = useState(false);

   const onSubmit = (data: any) => {
      if (!isVerified) {
         message.error(t('messages.captchaError'))
         return;
      }
      //@ts-ignore
      loginMutation(LOGIN, {
         endPoint: LOGIN_URL,
         body: {
            email: data.email,
            password: data.password
         }
      })
   }

   useEffect(() => {
      if (loginError) {
         console.log(loginError)
         message.error(t('messages.loginError'))
      }
   }, [loginError])

   useEffect(() => {
      if (loginData) {
         console.log(loginData)
         message.success(t('messages.loginSuccess'))
         localStorage.setItem('token', loginData.access_token)
         localStorage.setItem('user', JSON.stringify(loginData.user))
         window.location.href = routes.index
      }
   }, [loginData])

   const [form] = Form.useForm()
   const key = "6LcvCGwpAAAAAEM13K05bMWszy1KowCfnnWMitUv";
   return (
      <>
         <Form form={form} onFinish={onSubmit}>
            {loginError && (
               <Alert color="danger" className="mb-5">
                  <div className="text-left text-red-500">{t('messages.loginError')}</div>
               </Alert>
            )}
            <div className="space-y-5">
               <Form.Item
                  name="email"
                  rules={[
                     {
                        required: true,
                        message: t('validation.emailRequired')
                     },
                     {
                        type: 'email',
                        message: t('validation.emailFormat')
                     }
                  ]}
               >
                  <Input
                     type="email"
                     size="lg"
                     label={t('auth.email')}
                     color="info"
                     className="[&>label>span]:font-medium"
                     inputClassName="text-sm"
                  />
               </Form.Item>
               <Form.Item
                  name="password"
                  rules={[
                     {
                        required: true,
                        message: t('validation.passwordRequired')
                     }
                  ]}
               >
                  <Password
                     label={t('auth.password')}
                     size="lg"
                     className="[&>label>span]:font-medium"
                     inputClassName="text-sm"
                     color="info"
                  />
               </Form.Item>
               <div className="flex items-center justify-between pb-2">
                  <Checkbox
                     label={t('auth.remember')}
                     color="info"
                     variant="flat"
                     className="[&>label>span]:font-medium"
                  />
                  {/* <Link
                                to={routes.auth.forgotPassword}
                                className="h-auto p-0 text-sm font-semibold text-blue underline transition-colors hover:text-gray-900 hover:no-underline"
                            >
                                Şifrenizi mi unuttunuz?
                            </Link> */}
               </div>
               <ReCAPTCHA
                  sitekey={key}
                  onChange={() => setIsVerified(true)}

               />
               <Button isLoading={loginLoading} className="w-full" type="submit" size="lg" color="info">
                  <span>{t('auth.login')}</span> <PiArrowRightBold className="ms-2 mt-0.5 h-5 w-5" />
               </Button>
               <div className="flex items-center justify-center">
                  {/*forgot password*/}
                  <Link
                     to="#"
                     onClick={e => {
                        window.location.href = 'mailto:' + mail
                        e.preventDefault()
                     }}
                     className="h-auto p-0 text-sm font-semibold text-blue underline transition-colors hover:text-gray-900 hover:no-underline"
                  >
                     {t('auth.forgot')}
                  </Link>
               </div>
            </div>

         </Form>
      </>
   )
}
