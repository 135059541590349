//@ts-ignore
import React, { useEffect } from 'react'
import AppLayout from 'components/layouts/AppLayout'
import { ThemeProvider } from 'next-themes'
import { Toaster } from 'react-hot-toast'
import { NextUIProvider } from '@nextui-org/react'
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom'
import Login from 'pages/auth/Login'
import axios from 'axios'
import { apiUrl } from 'api'
import moment from 'moment'

import ClientLogin from 'pages/client-login'
import { Provider } from 'react-redux'
import { store } from './store'
import { routes } from 'config/routes'
import { useTranslation } from 'react-i18next'
import useFetch from 'hooks/use-fetch'
import { APP_LIST, APP_LIST_URL } from 'api/app'
import { Helmet } from 'react-helmet'
import Wrapper from 'Wrapper'

function App() {
   const { t, i18n } = useTranslation()
   axios.interceptors.request.use(
      config => {
         // kimlik bilgilerini gönderme durumu
         config.withCredentials = true
         config.baseURL = apiUrl + '/api'
         config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')
         config.withCredentials = true
         return config
      },
      function (error) {
         return Promise.reject(error)
      }
   )

   axios.interceptors.response.use(
      (response: any) => {
         switch (response.data.status) {
            case 401:
               localStorage.removeItem('token')
               localStorage.removeItem('user')
               window.location.href = routes.auth.login
               return

            default:
         }

         return response
      },
      function (error) {
         if (error.response.status === 401) {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            if (window.location.pathname !== routes.auth.login) {
               window.location.href = routes.auth.login
            }
         }
         return Promise.reject(error)
      }
   )

   moment.locale('en')

   const [loading, setLoading] = React.useState<boolean>(true)

   useEffect(() => {
      if (localStorage.getItem('locale')) {
         console.log(localStorage.getItem('locale'))
         i18n.changeLanguage(localStorage.getItem('locale')!)
      } else {
         localStorage.setItem('locale', 'de')
         i18n.changeLanguage('de')
      }
   }, [])

   return (
      <Provider store={store}>
         <ThemeProvider defaultTheme="light">
            <NextUIProvider>
               <Wrapper />
            </NextUIProvider>
            <Toaster />
         </ThemeProvider>
      </Provider>
   )
}

export default App
