import { Button, Chip, User, Card } from '@nextui-org/react'
import { Form, Modal, Popconfirm, Table, TableColumnProps, message } from 'antd'
import {
   REFERRALS_DELETE,
   REFERRALS_LIST,
   REFERRALS_LIST_URL,
   REFERRALS_STORE,
   REFERRALS_STORE_URL,
   REFERRALS_UPDATE,
   REFERRALS_UPDATE_URL,
   USER_REFERRALS_LIST_URL
} from 'api/referrals'
import TableComponents from 'components/Table'
import PageHeader from 'components/page-header'
import { routes } from 'config/routes'
import { MoneyFormat } from 'hooks/currency'
import useFetch from 'hooks/use-fetch'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export default function MyReferrals() {
   const { t } = useTranslation()
   const pageHeader = {
      title: t('aside.referral'),
      breadcrumb: [
         {
            href: routes.dashboard,
            name: t('aside.dashboard')
         },
         {
            href: routes.referrals.list,
            name: t('aside.referral')
         }
      ]
   }

   const [page, setPage] = React.useState(1)

   const [referralsQuery, { data, loading }] = useFetch<any>()

   useEffect(() => {
      //@ts-ignore
      referralsQuery(REFERRALS_LIST, {
         endPoint: USER_REFERRALS_LIST_URL.replace(':page', page.toString())
      })
   }, [page])

   const columns: TableColumnProps<any>[] = [
      {
         title: 'ID',
         dataIndex: 'id',
         key: 'id'
      },
      {
         title: t('common.transactionType'),
         dataIndex: 'type',
         key: 'type',
         className: 'capitalize',
         render: (type: any) => {
            return <div className="flex items-center gap-3">{type}</div>
         }
      },
      {
         title: t('common.amount'),
         dataIndex: 'amount',
         key: 'amount',
         render: (amount: any) => {
            return <div className="flex items-center gap-3">{MoneyFormat(amount)}</div>
         }
      },
      {
         title: t('common.createdAt'),
         dataIndex: 'createdAt',
         key: 'createdAt',
         render: (createdAt: any) => {
            return <div className="flex items-center gap-3">{moment(createdAt).fromNow()}</div>
         }
      }
   ]

   return (
      <div>
         <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
            <div className="mt-4 flex items-center gap-3 @lg:mt-0"></div>
         </PageHeader>
         <div className="@container">
            <Card title={t('aside.referral')}>
               {/* <Table
            columns={columns}
            dataSource={data?.data?.data}
            rowKey="id"
            pagination={false}
            loading={loading}
          /> */}
               <TableComponents
                  columns={columns}
                  data={data?.data?.data || []}
                  loading={loading}
                  pagination={{
                     total: data?.data?.total || 0,
                     current: page,
                     pageSize: 10,
                     onChange: (page: any) => setPage(page)
                  }}
               />
            </Card>
         </div>
      </div>
   )
}
