import axios from "axios";
import { FetchProps } from "./interface";

export const IPOS_LIST_URL = '/ipos?page=:page';

export const USER_IPOS_LIST_URL = '/ipos/user?page=:page';

export const IPOS_STORE_URL = '/ipos';

export const IPOS_UPDATE_URL = '/ipos';

export const IPOS_LIST = async (props:FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .get(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            return { error };
        });
};

export const IPOS_STORE = async (props:FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .post(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            return { error };
        });
}

export const IPOS_UPDATE = async (props:FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .put(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            return { error };
        });
}

export const IPOS_DELETE = async (props:FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .delete(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            return { error };
        });
}

export const IPOS_SHOW = async (props:FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .get(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            return { error };
        });
}