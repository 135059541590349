'use client'

import cn from 'utils/class-names'
import { IconEqual, IconMinus, IconPlus, IconWall, IconWallet, IconWalletOff } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import axios from 'axios'
import TrendingUpIcon from 'components/icons/trending-up'
import TrendingDownIcon from 'components/icons/trending-down'
import { MoneyFormat, MoneyFormat3 } from 'hooks/currency'
import { Tooltip } from 'recharts'
import { Button, Card, CardBody, CardHeader } from '@nextui-org/react'
import CardLoading from './CardLoading'
import { AnimatedCounter } from 'components/react-animated-counter-main/src'
import Counter from 'components/Counter'

export default function StatCards({ className }: { className?: string }) {
   const { t } = useTranslation()

   const [data, setData] = useState<any>({
      cashAvailable: 0,
      totalInvested: {
         ipo: 0,
         invested: 0,
         historical: 0,
         total: 0
      },
      profitLossPrice: {
         stock: 0,
         invested: 0,
         total: 0
      },
      profitLossPercent: 0,
      bonus: 0,
      portfolioValue: 0
   })
   const [loading, setLoading] = useState<boolean>(false)

   const fetchUserDashboard = async () => {
      setLoading(true)
      const { data } = await axios.post('/auth/dashboard')
      setLoading(false)

      // setData(data.data)

      setData({
         cashAvailable: data.data.cashAvailable,
         totalInvested: {
            ipo: data.data.totalInvested.ipo,
            invested: data.data.totalInvested.invested,
            historical: data.data.totalInvested.historical,
            total: data.data.totalInvested.total
         },
         profitLossPrice: {
            stock: MoneyFormat3(data.data.profitLossPrice.stock),
            invested: data.data.profitLossPrice.invested,
            total: data.data.profitLossPrice.total
         },
         profitLossPercent: data.data.profitLossPercent,
         bonus: data.data.bonus,
         portfolioValue: data.data.portfolioValue
      })
   }

   useEffect(() => {
      fetchUserDashboard()
   }, [])

   const hideOnMetric = ['Invested']

   return (
      <div
         className={cn('grid grid-cols-5 gap-5 items-center', className)}
         style={{
            zoom: 0.8
         }}
      >
         <div className='flex  gap-2 items-center'>
            <Card className=" w-5/6 h-[150px]">
               <CardHeader className="border-b">
                  <div className="flex items-center gap-2">
                     <div className="w-[40px] h-[40px] flex items-center justify-center border border-slate">
                        <IconWallet size={20} />
                     </div>
                     <span className="text-md text-gray-500">{t('common.cashAvailable')}</span>
                  </div>
               </CardHeader>
               <CardBody>
                  <span className="flex gap-[5px] items-center">
                     <span className="block text-2xl font-semibold for-mobile-text">
                        {/* <AnimatedCounter
                        fontSize="30px"
                        includeDecimals
                        decimalPrecision={2}
                        value={data?.cashAvailable}
                     /> */}
                        {MoneyFormat3(data?.cashAvailable)}
                        {/* <Counter value={data?.cashAvailable} /> EUR */}
                     </span>
                  </span>
               </CardBody>
            </Card>
            <span className='1-/6'>
               <IconPlus size={50} className="text-success" />
            </span>
         </div>

         <div className='flex  gap-2 items-center'>
            <Card className="h-[150px] w-5/6">
               <CardHeader className="border-b">
                  <div className="flex items-center gap-2">
                     <div className="w-[40px] h-[40px] flex items-center justify-center border border-slate">
                        <IconWallet size={20} />
                     </div>
                     <span className="text-md text-gray-500">{t('common.totalInvested')}</span>
                  </div>
               </CardHeader>
               <CardBody>
                  <span className="flex gap-[5px] items-center">
                     <span className="block text-2xl font-semibold for-mobile-text">
                        {/* {MoneyFormat(data?.totalInvested)} */}
                        {/* <AnimatedCounter fontSize="30px" value={data?.totalInvested?.total} /> */}
                        {MoneyFormat3(data?.totalInvested?.total)}
                        {/* <Counter value={data?.totalInvested} /> EUR */}
                     </span>
                  </span>
               </CardBody>
            </Card>

            <span className="w-1/6">
               {data?.profitLossPercent >= 0 ? (
                  <IconPlus size={50} className="text-success" />
               ) : (
                  <IconMinus size={50} className="text-danger" />
               )}
            </span>
         </div>
         <div className='flex gap-2 items-center'>
            <Card className="h-[150px] w-5/6">
               <CardHeader className="border-b flex flex-col items-start">
                  <div className="flex items-center gap-2">
                     <div className="w-[40px] h-[40px] flex items-center justify-center border border-slate">
                        <IconWallet size={20} />
                     </div>
                     <span className="text-md text-gray-500">{t('common.profitLoss')}</span>
                  </div>
               </CardHeader>
               <CardBody>
                  <span className="flex flex-col">
                     <div>
                        <span className="block text-lg font-semibold for-mobile-text">
                           {MoneyFormat3(data?.profitLossPrice?.total)}
                           {/* <AnimatedCounter fontSize="30px" value={data?.profitLossPrice} /> */}
                           {/* <Counter value={data?.profitLossPrice} /> EUR */}
                        </span>
                     </div>
                     <span
                        className={cn(
                           'me-2 inline-flex items-center font-medium',
                           data?.profitLossPercent >= 0 ? 'text-green' : 'text-red'
                        )}
                     >
                        {/* <TrendingUpIcon className="me-1 h-4 w-4" /> */}
                        {data?.profitLossPercent > 0 ? (
                           <TrendingUpIcon className="me-1 h-4 w-4" />
                        ) : (
                           <TrendingDownIcon className="me-1 h-4 w-4" />
                        )}
                        {data?.profitLossPercent}%
                     </span>
                  </span>
               </CardBody>
            </Card>
            <span className="">
               <IconPlus size={50} className="text-success" />
            </span>
         </div>

         <div className='flex gap-2 items-center'>
            <Card className="h-[150px] w-5/6">
               <CardHeader className="border-b">
                  <div className="flex items-center gap-2">
                     <div className="w-[40px] h-[40px] flex items-center justify-center border border-slate">
                        <IconWallet size={20} />
                     </div>
                     <span className="text-md text-gray-500">{t('common.totalBonus')}</span>
                  </div>
               </CardHeader>
               <CardBody>
                  <span className="flex flex-col">
                     <span className="block text-2xl font-semibold for-mobile-text">{MoneyFormat3(data?.bonus)}</span>
                     <span className="block text-2xl font-semibold for-mobile-text">
                        {/* <AnimatedCounter value={data?.bonus} /> */}
                        {/* <Counter value={data?.bonus} /> EUR */}
                     </span>
                  </span>
               </CardBody>
            </Card>
            <div className='w-1/6'>
               <span className="">
                  <IconEqual size={50} className="text-success" />
               </span>
            </div>
         </div>

         <Card className="h-[150px]">
            <CardHeader className="border-b">
               <div className="flex items-center gap-2">
                  <div className="w-[40px] h-[40px] flex items-center justify-center border border-slate">
                     <IconWallet size={20} />
                  </div>
                  <span className="text-md text-gray-500">{t('common.portfolioValue')}</span>
               </div>
            </CardHeader>
            <CardBody>
               <span className="flex gap-[5px] items-center">
                  <span className="block text-2xl font-semibold for-mobile-text">
                     {MoneyFormat3(data?.portfolioValue)}
                     {/* <AnimatedCounter fontSize="30px" value={data?.portfolioValue} /> */}
                     {/* <Counter value={data?.portfolioValue} /> EUR */}
                  </span>
               </span>
            </CardBody>
         </Card>
      </div>
   )
}
