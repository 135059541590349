import React, {useEffect} from 'react';

const TradingViewWidget = () => {


    return (
        <div className="tradingview-widget-container">
           <iframe width={"100%"} height={600} src="https://www.tradingview-widget.com/embed-widget/market-quotes/?locale=de_DE#%7B%22width%22%3A500%2C%22height%22%3A500%2C%22symbolsGroups%22%3A%5B%7B%22name%22%3A%22AKTIEN%22%2C%22symbols%22%3A%5B%7B%22name%22%3A%22XETR%3ABAYN%22%2C%22displayName%22%3A%22BAYER%20AG%20NA%20O.N.%22%7D%2C%7B%22name%22%3A%22XETR%3AALV%22%2C%22displayName%22%3A%22ALLIANZ%20SE%20NA%20O.N.%22%7D%2C%7B%22name%22%3A%22XETR%3ABMW%22%2C%22displayName%22%3A%22BAYERISCHE%20MOTORENWERKE%20AG%22%7D%2C%7B%22name%22%3A%22XETR%3AADS%22%2C%22displayName%22%3A%22ADIDAS%20AG%20NA%20O.N.%22%7D%2C%7B%22name%22%3A%22XETR%3AMBG%22%2C%22displayName%22%3A%22MERCEDES-BENZ%20GROUP%20AG%22%7D%2C%7B%22name%22%3A%22XETR%3ADB1%22%2C%22displayName%22%3A%22DEUTSCHE%20BOERSE%20AG%22%7D%2C%7B%22name%22%3A%22XETR%3A22UA%22%2C%22displayName%22%3A%22BIONTECH%20SE%22%7D%2C%7B%22name%22%3A%22FWB%3AO9T%22%2C%22displayName%22%3A%22ARM%20HOLDINGS%20AKTIE%22%7D%2C%7B%22name%22%3A%22XETR%3ASIE%22%2C%22displayName%22%3A%22SIEMENS%20AG%20NA%20O.N.%22%7D%2C%7B%22name%22%3A%22XETR%3AVNA%22%2C%22displayName%22%3A%22VONOVIA%20AG%22%7D%2C%7B%22name%22%3A%22XETR%3AP911%22%2C%22displayName%22%3A%22PORSCHE%20AG%20VZ%22%7D%2C%7B%22name%22%3A%22XETR%3ARHM%22%2C%22displayName%22%3A%22RHEINMETALL%20AG%22%7D%5D%7D%5D%2C%22showSymbolLogo%22%3Atrue%2C%22colorTheme%22%3A%22light%22%2C%22isTransparent%22%3Afalse%2C%22utm_source%22%3A%22localhost%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22market-quotes%22%2C%22page-uri%22%3A%22localhost%3A3000%2Fdashboard%22%7D"></iframe>
        </div>
    );
};

export default TradingViewWidget;
