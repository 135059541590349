import { Button, Chip, User, Card, CardHeader, CardBody } from '@nextui-org/react'
import { Form, Modal, Popconfirm, Table, TableColumnProps, message } from 'antd'
import {
   IPOS_DELETE,
   IPOS_LIST,
   IPOS_LIST_URL,
   IPOS_STORE,
   IPOS_STORE_URL,
   IPOS_UPDATE,
   IPOS_UPDATE_URL
} from 'api/ipos'
import PageHeader from 'components/page-header'
import { routes } from 'config/routes'
import { MoneyFormat, MoneyFormat2 } from 'hooks/currency'
import useFetch from 'hooks/use-fetch'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import IposForm from './Form'
import cn from 'utils/class-names'
import ActionButton from './ActionButton'
import TableComponents from 'components/Table'
import UserFilter from 'components/FilterContainer/User'
import SearchInput from 'components/Input'
import DateRange from 'components/FilterContainer/DateRange'

export default function Ipos() {
   const { t } = useTranslation()
   const pageHeader = {
      title: t('aside.ipos'),
      breadcrumb: [
         {
            href: routes.dashboard,
            name: t('aside.dashboard')
         },
         {
            href: routes.ipos.list,
            name: t('aside.ipos')
         }
      ]
   }

   const [page, setPage] = React.useState(1)
   const [search, setSearch] = React.useState('')
   const [selectedUser, setSelectedUser] = React.useState<any>(null)
   const [startDate, setStartDate] = React.useState<any>(null)
   const [endDate, setEndDate] = React.useState<any>(null)
   const [row, setRow] = React.useState<any>({}) // row selection
   const [addModal, setAddModal] = React.useState(false)
   const [editModal, setEditModal] = React.useState(false)

   const addOnOpenModal = () => setAddModal(true)
   const addOnCloseModal = () => setAddModal(false)
   const addOpenModal = addModal
   const editOnOpenModal = () => setEditModal(true)
   const editOnCloseModal = () => setEditModal(false)
   const editOpenModal = editModal

   const [referralsQuery, { data, loading }] = useFetch<any>()

   const [addIposMutation, { data: addIposData, loading: addIposLoading }] = useFetch<any>()

   const [editIposMutation, { data: editIposData, loading: editIposLoading }] = useFetch<any>()

   const [deleteIposMutation, { data: deleteIposData, loading: deleteIposLoading }] = useFetch<any>()

   useEffect(() => {
      //@ts-ignore
      referralsQuery(IPOS_LIST, {
         endPoint: IPOS_LIST_URL.replace(':page', page.toString())
      })
   }, [page])

   const columns: TableColumnProps<any>[] = [
      {
         title: 'ID',
         dataIndex: 'id',
         key: 'id'
      },
      {
         title: t('common.user'),
         dataIndex: 'user',
         key: 'user',
         render: (user: any) => {
            return (
               <div className="flex items-center gap-3">
                  <span>
                     {user?.name} {user?.last_name}
                  </span>
               </div>
            )
         }
      },
      {
         title: t('common.contactDate'),
         dataIndex: 'contact_date',
         key: 'contact_date',
         render: (contact_date: any) => {
            return <div className="flex items-center gap-3">{moment(contact_date).format('DD.MM.YYYY')}</div>
         }
      },
      {
         title: t('common.iposWkn'),
         dataIndex: 'id',
         key: 'id',
         render: (pieces: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  <span>{record.name}</span>
                  <span>{record.wkn}</span>
               </div>
            )
         }
      },

      {
         title: t('common.pieces'),
         dataIndex: 'pieces',
         key: 'pieces',
         render: (pieces: any) => {
            return <div className="flex items-center gap-3">{pieces}</div>
         }
      },
      {
         title: t('common.price'),
         dataIndex: 'price',
         key: 'price',
         render: (price: any) => {
            return <div className="flex items-center gap-3">{MoneyFormat2(price)}</div>
         }
      },

      {
         title: t('common.total'),
         dataIndex: 'total',
         key: 'total',
         render: (total: any, record: any) => {
            return <div className="flex items-center gap-3">{MoneyFormat2(record.price * record.pieces)}</div>
         }
      },

      {
         title: t('common.bookBuilding'),
         dataIndex: 'price',
         key: 'price',
         render: (price: any, record: any) => {
            return <div className="flex items-center gap-3">{record.bookBuilding}</div>
         }
      },
      {
         title: t('common.actions'),
         dataIndex: 'id',
         key: 'id',
         render: (id: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  {/* <Button
              onClick={() => {
                setRow(record);
                editOnOpenModal();
              }}
              color="primary"
              variant="faded"
            >
              {t("buttons.edit")}
            </Button>
            <Popconfirm
              title={t("messages.delete_confirm")}
              onConfirm={() => {
                //@ts-ignore
                deleteIposMutation(IPOS_DELETE, {
                  endPoint: IPOS_UPDATE_URL + "/" + id,
                });
              }}
              okButtonProps={{
                loading: deleteIposLoading,
                className: "bg-red-500",
                style: { background: "red" },
              }}
              okText={t("buttons.delete")}
              cancelText={t("buttons.cancel")}
            >
              <Button color="danger" variant="faded">
                {t("buttons.delete")}
              </Button>
            </Popconfirm> */}
                  <ActionButton
                     deleteButtonClick={() => {
                        //@ts-ignore
                        Modal.confirm({
                           title: t('messages.delete_confirm'),
                           okText: t('buttons.delete'),
                           cancelText: t('buttons.cancel'),
                           onOk: () => {
                              //@ts-ignore
                              deleteIposMutation(IPOS_DELETE, {
                                 endPoint: IPOS_UPDATE_URL + '/' + id
                              })
                           }
                        })
                     }}
                     editButtonClick={() => {
                        setRow(record)
                        editOnOpenModal()
                     }}
                  />
               </div>
            )
         }
      }
   ]

   const [addForm] = Form.useForm()

   const [editForm] = Form.useForm()

   const onFilter = () => {
      let object: any = {}
      if (selectedUser) {
         object.user_id = selectedUser
      }

      if (search) {
         object.search = search
      }

      if (startDate) {
         object.start_date = startDate
      }

      if (endDate) {
         object.end_date = endDate
      }

      let query = new URLSearchParams(object).toString()

      //@ts-ignore
      referralsQuery(IPOS_LIST, {
         endPoint: IPOS_LIST_URL.replace(':page', (1).toString() + `&${query}`)
      })
   }

   const onAddFinish = (values: any) => {
      //@ts-ignore
      addIposMutation(IPOS_STORE, {
         endPoint: IPOS_STORE_URL,
         body: values
      })
   }

   const onUpdateFinish = (values: any) => {
      //@ts-ignore
      editIposMutation(IPOS_UPDATE, {
         endPoint: IPOS_UPDATE_URL + '/' + row.id,
         body: values
      })
   }

   const onDeleteFinish = (values: any) => {
      //@ts-ignore
      deleteIposMutation(IPOS_DELETE, {
         endPoint: IPOS_UPDATE_URL + '/' + row.id,
         body: values
      })
   }

   useEffect(() => {
      if (addIposData) {
         if (addIposData?.status == false) {
            message.error(t(addIposData?.message))
         }
         else {
            message.success(t('messages.add_success'))
            addOnCloseModal()
            //@ts-ignore
            referralsQuery(IPOS_LIST, {
               endPoint: IPOS_LIST_URL.replace(':page', page.toString())
            })
            addForm.resetFields()
         }
      }
   }, [addIposData])

   useEffect(() => {
      if (editIposData) {
         message.success(t('messages.update_success'))
         editOnCloseModal()
         //@ts-ignore
         referralsQuery(IPOS_LIST, {
            endPoint: IPOS_LIST_URL.replace(':page', page.toString())
         })
         editForm.resetFields()
      }
   }, [editIposData])

   useEffect(() => {
      if (deleteIposData) {
         message.success(t('messages.delete_success'))
         //@ts-ignore
         referralsQuery(IPOS_LIST, {
            endPoint: IPOS_LIST_URL.replace(':page', page.toString())
         })
      }
   }, [deleteIposData])

   return (
      <div>
         <Modal title={t('buttons.add')} open={addOpenModal} onCancel={addOnCloseModal} footer={null}>
            <Form form={addForm} onFinish={onAddFinish} layout="vertical">
               <IposForm form={addForm} isLoading={addIposLoading} onFinish={onAddFinish} />
               <div className="flex gap-5 items-end">
                  <Button color="danger" variant="light" onPress={addOnCloseModal}>
                     {t('buttons.cancel')}
                  </Button>
                  <Button color="primary" variant="light" type="submit" isLoading={addIposLoading}>
                     {t('buttons.add')}
                  </Button>
               </div>
            </Form>
         </Modal>
         <Modal title={t('buttons.edit')} open={editOpenModal} onCancel={editOnCloseModal} footer={null}>
            <Form form={editForm} onFinish={onUpdateFinish} layout="vertical">
               <IposForm form={editForm} isLoading={editIposLoading} onFinish={onUpdateFinish} initialValues={row} />
               <div className="flex gap-5 items-end">
                  <Button color="danger" variant="light" onPress={editOnCloseModal}>
                     {t('buttons.cancel')}
                  </Button>
                  <Button color="primary" variant="light" type="submit" isLoading={editIposLoading}>
                     {t('buttons.save')}
                  </Button>
               </div>
            </Form>
         </Modal>

         <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
            <div className="mt-4 flex items-center gap-3 @lg:mt-0"></div>
            <Button variant="solid" color="primary" onPress={addOnOpenModal}>
               {t('buttons.addIpo')}
            </Button>
         </PageHeader>
         <div className="@container">
            <Card title="Filter" className="mb-5">
               <CardHeader>
                  <h3 className="text-lg font-semibold">{t('common.filterTable')}</h3>
               </CardHeader>
               <CardBody className="grid grid-cols-4 gap-2 items-center">
                  <UserFilter onChange={(e: any) => setSelectedUser(e.value)} value={selectedUser} />
                  <SearchInput onChange={(e: any) => setSearch(e.target.value)} value={search} />
                  <DateRange
                     onChangeOne={(e: any) => setStartDate(e.target.value)}
                     onChangeTwo={(e: any) => setEndDate(e.target.value)}
                     valueOne={startDate}
                     valueTwo={endDate}
                     hasOne={true}
                     hasTwo={true}
                  />
                  <Button color="primary" variant="solid" onClick={() => onFilter()} className="mt-5">
                     {t('common.filter')}
                  </Button>
               </CardBody>
            </Card>
            <Card title={t('aside.ipos')}>
               {/* <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 px-10">
            <thead className="text-md text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr className={""}>
                {columns.map((column: any, index) => {
                  return (
                    <th
                      key={column.uid}
                      className={cn("px-2 py-2 font-semibold")}
                    >
                      <div className={cn("", column.className)}>
                        {column.title}
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data?.data?.data.map((row: any, index: number) => {
                return (
                  <tr
                    key={index}
                    className="border-b border-gray-100 dark:border-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700"
                  >
                    {columns.map((column: any, index) => {
                      return (
                        <td
                          key={column.uid}
                          className={cn("px-2 py-2", column.className)}
                        >
                          <div
                            className={cn("font-semibold", column.className)}
                          >
                            {column.render
                              ? column.render(row[column.dataIndex], row)
                              : row[column.dataIndex]}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
              {data?.data?.data.length === 0 && (
                <tr>
                  <td colSpan={columns.length} className="text-center">
                    {t("common.noData")}
                  </td>
                </tr>
              )}
            </tbody>
          </table> */}
               <TableComponents
                  columns={columns}
                  data={data?.data?.data || []}
                  pagination={{
                     total: data?.data?.total,
                     current: page,
                     pageSize: 10,
                     onChange: (page: any) => {
                        setPage(page)
                     }
                  }}
                  loading={loading}
               />
            </Card>
         </div>
      </div>
   )
}
