import AuthWrapperOne from 'components/auth-layout/auth-wrapper-one'
import SignInForm from './LoginForm'
import { useEffect } from 'react'
import { routes } from 'config/routes'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

export default function Login() {
   const { t } = useTranslation()
   const app = useSelector((state: any) => state.app)
   useEffect(() => {
      if (localStorage.getItem('token')) {
         window.location.href = routes.index
      } else {
         console.log('token yok')
      }
   }, [])

   return (
      <AuthWrapperOne
         logo={app?.data?.logo}
         title={
            <>
               <span className="bg-gradient-to-r from-[#5156be] to-[#7176c9] bg-clip-text text-transparent">
                  {t('auth.welcome')}
               </span>{' '}
               <div>{t('auth.welcomeDesc')}</div>
            </>
         }
         isSignIn
         isSocialLoginActive={true}
      >
         <SignInForm mail={app?.data?.mail} logo={app?.data?.logo} />
      </AuthWrapperOne>
   )
}
