import React, { useState } from 'react'
import { Form, Popover, Select, message } from 'antd'
import { IconEdit } from '@tabler/icons-react'
import { Input } from 'rizzui'
import { Button } from '@nextui-org/react'
import CurrencyInput from 'react-currency-input-field'
import { NumericFormat } from 'react-number-format'
import axios from 'axios'
import { MoneyFormat } from 'hooks/currency'

interface Props {
   row: any
}

const BalanceColumn = (props: Props) => {
   const { row } = props
   const [open, setOpen] = useState(false)
   const [form] = Form.useForm()
   const [loading, setLoading] = useState(false)

   const hide = () => {
      setOpen(false)
   }

   const handleOpenChange = (newOpen: boolean) => {
      setOpen(newOpen)
   }

   const onFinish = async (values: any) => {
      try {
         setLoading(true)
         const { data } = await axios.put(`/update-user-balance`, {
            amount: values.balance,
            type: values.type,
            user_id: row.id
         })
         hide()

         message.success('Balance updated successfully')
         setLoading(false)
      } catch (e) {
         message.error('Something went wrong')
      }
   }

   const PopoverContent = () => {
      return (
         <Form
            form={form}
            initialValues={{
               balance: parseFloat(row.balance)
            }}
            onFinish={onFinish}
            layout="vertical"
            name="balance-form"
         >
            <Form.Item
               label="Type"
               name="type"
               rules={[
                  {
                     required: true,
                     message: 'Please input your type!'
                  }
               ]}
            >
               <Select placeholder="Select a option and change input text above" allowClear>
                  <Select.Option value="plus">Add</Select.Option>
                  <Select.Option value="minus">Remove</Select.Option>
               </Select>
            </Form.Item>
            <Form.Item
               label="Balance"
               name="balance"
               rules={[
                  {
                     required: true,
                     message: 'Please input your balance!'
                  }
               ]}
            >
               <NumericFormat
                  placeholder="0.00"
                  className="ant-input rizzui-input"
                  prefix="€"
                  decimalSeparator="."
                  thousandSeparator=","
                  customInput={Input}
                  onValueChange={(value: any) => {
                     console.log(value.floatValue)
                     form.setFieldValue('balance', value.floatValue)
                  }}
               />
               <input type="hidden" name="balance" />
            </Form.Item>
            <Button color="primary" type="submit" isLoading={loading}>
               Save
            </Button>
         </Form>
      )
   }

   return (
      <Popover
         content={<PopoverContent />}
         title="Update Balance"
         trigger="click"
         open={open}
         onOpenChange={handleOpenChange}
      >
         <Button color="primary" type="button">
            <span>{MoneyFormat(parseFloat(row.balance))}</span>
            <IconEdit size={20} />
         </Button>
      </Popover>
   )
}

export default BalanceColumn
