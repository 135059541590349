import { Button, Chip, User, Card } from '@nextui-org/react'
import { Table, TableColumnProps } from 'antd'
import { INVESTED_LIST, USER_INVESTED_LIST_URL } from 'api/invested'
import PageHeader from 'components/page-header'
import { routes } from 'config/routes'
import { MoneyFormat, MoneyFormat2 } from 'hooks/currency'
import useFetch from 'hooks/use-fetch'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export default function UserInvested() {
   const { t } = useTranslation()
   const navigate = useNavigate()
   const pageHeader = {
      title: t('aside.invested'),
      breadcrumb: [
         {
            href: routes.dashboard,
            name: t('aside.dashboard')
         },
         {
            href: routes.invested.list,
            name: t('aside.invested')
         }
      ]
   }

   const [page, setPage] = React.useState(1)

   const [referralsQuery, { data, loading }] = useFetch<any>()

   useEffect(() => {
      //@ts-ignore
      referralsQuery(INVESTED_LIST, {
         endPoint: USER_INVESTED_LIST_URL.replace(':page', page.toString())
      })
   }, [page])

   const columns: TableColumnProps<any>[] = [
      {
         title: 'ID',
         dataIndex: 'id',
         key: 'id'
      },
      {
         title: t('common.name'),
         dataIndex: 'user',
         key: 'user',
         render: (user: any) => {
            return <div className="flex items-center gap-3">{user.name}</div>
         }
      },

      {
         title: t('common.amount'),
         dataIndex: 'amount',
         key: 'amount',
         render: (amount: any) => {
            return <div className="flex items-center gap-3">{MoneyFormat2(amount)}</div>
         }
      },
      {
         title: t('common.startDate'),
         dataIndex: 'start_date',
         key: 'start_date',
         render: (start_date: any) => {
            return <div className="flex items-center gap-3">{moment(start_date).format('YYYY-MM-DD')}</div>
         }
      },
      {
         title: t('common.endDate'),
         dataIndex: 'end_date',
         key: 'end_date',
         render: (end_date: any) => {
            return <div className="flex items-center gap-3">{moment(end_date).format('YYYY-MM-DD')}</div>
         }
      },
      {
         title: t('common.period'),
         dataIndex: 'period',
         key: 'period',
         render: (period: any) => {
            return <div className="flex items-center gap-3">{period} month</div>
         }
      },
      {
         title: t('common.rate'),
         dataIndex: 'rate',
         key: 'rate',
         render: (rate: any) => {
            return <div className="flex items-center gap-3">{rate.toString().replace('.', ',')}%</div>
         }
      },
      {
         title: t('common.total'),
         dataIndex: 'period',
         key: 'period',
         render: (period: any, record: any) => {
            return <div className="flex items-center gap-3">{MoneyFormat2((record.rate * record.amount) / 100)}</div>
         }
      },
      {
         title: t('common.paymentPlan'),
         dataIndex: 'id',
         key: 'id',
         render: (id: any) => {
            return (
               <Button color="primary" variant="solid" onClick={() => {
                  navigate(routes.paymentPlan(id))
               }}>
                  {t('common.paymentPlan')}
               </Button>
            )
         }
      }
   ]

   return (
      <div>
         <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
            <div className="mt-4 flex items-center gap-3 @lg:mt-0"></div>
         </PageHeader>
         <div className="@container">
            <Card title={t('aside.invested')}>
               <Table
                  columns={columns}
                  dataSource={data?.data?.data}
                  rowKey="id"
                  pagination={false}
                  loading={loading}
               />
            </Card>
         </div>
      </div>
   )
}
