import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Modal, Switch, Table, TableColumnProps, Tag, message } from 'antd'
import { Button, Card } from '@nextui-org/react'
import { Input } from 'rizzui'
import { routes } from 'config/routes'
import useFetch from 'hooks/use-fetch'
import PageHeader from 'components/page-header'
import { STOCK_LIST, STOCK_LIST_URL, STOCK_UPDATE, STOCK_UPDATE_URL } from 'api/stock'
import EditForm from './Form'

const StocksPage = () => {
   const { t } = useTranslation()
   const pageHeader = {
      title: t('aside.stocks'),
      breadcrumb: [
         {
            href: routes.dashboard,
            name: t('aside.dashboard')
         },
         {
            href: routes.language.list,
            name: t('aside.stocks')
         }
      ]
   }

   const [updateModal, updateOnOpenModal] = useState(false)
   const [row, setRow] = useState<any>(null)


   const columns: TableColumnProps<any>[] = [
      {
         title: 'Name',
         dataIndex: 'name',
         key: 'name',
         sorter: (a, b) => a.name.localeCompare(b.name),
         render: (text, record) => (
            <>
               <span>{record.short_name}</span>
            </>
         )
      },
      {
         title: 'Symbol',
         dataIndex: 'symbol',
         key: 'symbol',
         sorter: (a, b) => a.symbol.localeCompare(b.symbol),
         render: (text, record) => (
            <>
               <span>{record.symbol}</span>
            </>
         )
      },
      {
         title: 'Regular Market Price',
         dataIndex: 'regular_market_price',
         key: 'regular_market_price',
         sorter: (a, b) => a.regular_market_price.localeCompare(b.regular_market_price),
         render: (text, record) => (
            <>
               <span>{record.regular_market_price}</span>
            </>
         )
      },

      {
         title: 'Actions',
         dataIndex: 'actions',
         key: 'actions',
         render: (text, record) => (
            <>
               <Button color="primary" onClick={() => { updateOnOpenModal(true); setRow(record) }} >
                  {t('buttons.edit')}
               </Button>
            </>
         )
      }
   ]

   const [fetchData, { data, loading, error }] = useFetch<any>()

   const [updateMutation, { data: updateData, loading: updateLoading, error: updateError }] = useFetch<any>()

   useEffect(() => {
      //@ts-ignore
      fetchData(STOCK_LIST, {
         endPoint: STOCK_LIST_URL
      })
   }, [])



   const [form] = Form.useForm()

   const onFinish = (values: any) => {
      const data = {
         regular_market_price: values.regular_market_price,
      }
      //@ts-ignore
      updateMutation(STOCK_UPDATE, {
         endPoint: STOCK_UPDATE_URL + '/' + row.id,
         body: data
      })
   }

   useEffect(() => {
      if (updateData) {
         message.success(t('messages.update_success'))
         updateOnOpenModal(false)
         setRow(null)
         //@ts-ignore
         fetchData(STOCK_LIST, {
            endPoint: STOCK_LIST_URL
         })
      }
   }, [updateData])

   return (
      <div>
         <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}></PageHeader>
         <Modal
            title="Update Stock"
            open={updateModal}
            onCancel={() => { updateOnOpenModal(false); setRow(null) }}
            footer={null}
         >
            <Form form={form} layout='vertical' onFinish={onFinish} initialValues={row}>
               <EditForm
                  values={row}
                  form={form}

               />
               <Button color="primary" type="submit" isLoading={updateLoading}>
                  {t('buttons.edit')}
               </Button>
            </Form>
         </Modal>
         <Card>
            <Table columns={columns} dataSource={data?.data} rowKey="id" loading={loading} />
         </Card>
      </div>
   )
}

export default StocksPage
