

import React from "react";
import { Route, Routes } from "react-router-dom";
import MyStocksTable from "./Home";


const MyStocks = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<MyStocksTable />} />
                <Route path="/without-currency" element={<MyStocksTable />} />
               
            </Routes>
        </div>
    );
};

export default MyStocks;
