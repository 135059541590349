import {FetchProps} from "./interface";
import axios from "axios";

export const ADD_LOCALE = async (props:FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .post(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            console.error("hata",error);
            return { error };
        });
};

export const GET_LOCALES = async (props:FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .get(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            console.error("hata",error);
            return { error };
        });
};

export const GET_LOCALE = async (props:FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .get(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            console.error("hata",error);
            return { error };
        });
};


export const UPDATE_LOCALE = async (props:FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .put(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            console.error("hata",error);
            return { error };
        });
};


