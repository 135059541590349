import React, { useState } from "react";
import { Form } from "antd";
import { Tabs } from "antd";
import { Input, Tab } from "rizzui";
import Sample from "locales/sample.json";

export default function LanguageForm() {
  const [tabs, setTabs] = useState([
    {
      id: 1,
      name: "Common",
      slug: "common",
    },
    {
      id: 2,
      name: "Messages",
      slug: "messages",
    },
    {
      id: 3,
      name: "Validation",
      slug: "validation",
    },
    {
      id: 4,
      name: "Aside",
      slug: "aside",
    },
    {
      id: 5,
      name: "Buttons",
      slug: "buttons",
    },
    {
      id: 6,
      name: "Dashboard",
      slug: "dashboard",
    },
    {
      id: 7,
      name: "Auth",
      slug: "auth",
    },
  ]);

  const [active, setActive] = useState(1);

  const ObjectKeys = Object.keys(Sample);

  console.log(ObjectKeys);

  return (
    <div className={"mt-5"}>
      <h3 className="text-2xl font-bold col-span-4">Common</h3>
      <div className="grid grid-cols-4 gap-5" key="1">

        {Object.keys(Sample.common).map((key: any, index: number) => {
          return (
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
              label={key}
              name={['common', key]}
            >
              <Input placeholder={key} className="ant-input rizzui-input" />
            </Form.Item>
          );
        })}
      </div>
      <h3 className="text-2xl font-bold col-span-4">Messages</h3>
      <div className="grid grid-cols-4 gap-5" key="2">
        {Object.keys(Sample.messages).map((key: any, index: number) => {
          return (
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
              label={key}
              name={['messages', key]}
            >
              <Input placeholder={key} className="ant-input rizzui-input" />
            </Form.Item>
          );
        })}
      </div>
      <h3 className="text-2xl font-bold col-span-4">Validation</h3>
      <div className="grid grid-cols-4 gap-5" key="3">
        {Object.keys(Sample.validation).map((key: any, index: number) => {
          return (
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
              label={key}
              name={['validation', key]}
            >
              <Input placeholder={key} className="ant-input rizzui-input" />
            </Form.Item>
          );
        })}
      </div>
      <h3 className="text-2xl font-bold col-span-4">Aside</h3>
      <div className="grid grid-cols-4 gap-5" key="4">
        {Object.keys(Sample.aside).map((key: any, index: number) => {
          return (
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
              label={key}
              name={['aside', key]}
            >
              <Input placeholder={key} className="ant-input rizzui-input" />
            </Form.Item>
          );
        })}
      </div>
      <h3 className="text-2xl font-bold col-span-4">Buttons</h3>
      <div className="grid grid-cols-4 gap-5" key="5">
        {Object.keys(Sample.buttons).map((key: any, index: number) => {
          return (
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
              label={key}
              name={['buttons', key]}
            >
              <Input placeholder={key} className="ant-input rizzui-input" />
            </Form.Item>
          );
        })}
      </div>
      <h3 className="text-2xl font-bold col-span-4">Dashboard</h3>
      <div className="grid grid-cols-4 gap-5" key="6">
        {Object.keys(Sample.dashboard).map((key: any, index: number) => {
          return (
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
              label={key}
              name={['dashboard', key]}
            >
              <Input placeholder={key} className="ant-input rizzui-input" />
            </Form.Item>
          );
        })}
      </div>
      <h3 className="text-2xl font-bold col-span-4">Auth</h3>
      <div className="grid grid-cols-4 gap-5" key="7">
        {Object.keys(Sample.auth).map((key: any, index: number) => {
          return (
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
              label={key}
              name={['auth', key]}
            >
              <Input placeholder={key} className="ant-input rizzui-input" />
            </Form.Item>
          );
        })}
      </div>

    </div>
  );
}
