import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface AppState {
   data: {}
}

const initialState: AppState = {
   data: {}
}

export const appSlice = createSlice({
   name: 'app',
   initialState,
   reducers: {
      setApp: (state, action: PayloadAction<AppState>) => {
         state.data = action.payload
      }
   }
})

// Action creators are generated for each case reducer function
export const { setApp } = appSlice.actions

export default appSlice.reducer
