import Header from 'components/app/Header'
import Sidebar from 'components/app/Sidebar'
import EcommerceDashboard from 'pages/admin/home/Dashboard'

import React, { Suspense, useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import GlobalDrawer from 'components/shared/drawer-views/container'
import TradingViewWidget from './TradingViewWidget'
import MyStocks from '../../pages/admin/my-stocks/Page'
import LiveTradingPage from 'pages/admin/live-trading/Page'
import axios from 'axios'
import TransactionTable from 'pages/admin/transaction/page'
import UsersPage from 'pages/admin/users/Route'
import Activities from 'pages/admin/live-trading/Activity'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store'
import { setShouldUpdateProfile, setUser } from 'features/user/user'
import Referrals from 'pages/admin/referrals/page'
import MyReferrals from 'pages/admin/referrals/my-referrals'
import { routes } from 'config/routes'
import Ipos from 'pages/admin/ipos/page'
import Invested from 'pages/admin/invested/page'
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure } from '@nextui-org/react'
import { Divider, Form, message, Input } from 'antd'
import { Alert, Title } from 'rizzui'
import { useTranslation } from 'react-i18next'
import useFetch from 'hooks/use-fetch'
import { USER_UPDATE } from 'api/user'
import UserIpos from 'pages/admin/ipos/user-ipos'
import pusherJs from 'pusher-js'
import UserInvested from 'pages/admin/invested/user-invested'
import MySellStocksTable from 'pages/admin/my-stocks/SellTable'
import LanguageSettings from '../../pages/admin/settings/language/page'
import EditLanguage from '../../pages/admin/settings/language/edit'
import StocksPage from 'pages/admin/stocks/page'
import EditAppSettings from 'pages/admin/settings/app/Edit'
import PaymentPlan from 'pages/admin/invested/PaymentPlan'
const AppLayout = () => {
   const [messageApi, contextHolder] = message.useMessage()

   const key = 'updatable'
   const { t } = useTranslation()
   const user = useSelector((state: RootState) => state.user)
   const [row, setRow] = React.useState<any>({}) // row selection
   const [updateProfile, { data, loading, error }] = useFetch<any>()

   const dispatch = useDispatch()
   useEffect(() => {
      if (!localStorage.getItem('token')) {
         window.location.href = routes.auth.login
      }
   }, [])

   const [openSidebar, setOpenSidebar] = React.useState(false)

   const auth = async () => {
      const { data } = await axios.post('/auth/me')
      dispatch(setUser(data))
      setRow(data)
      if (
         !data.last_name ||
         !data.country ||
         !data.city ||
         !data.street ||
         !data.postal_code ||
         !data.phone_number ||
         !data.date_of_birth
      ) {
         console.log(
            data.last_name,
            data.country,
            data.city,
            data.street,
            data.postal_code,
            data.phone_number,
            data.date_of_birth
         )
         onOpen()
      }
   }

   useEffect(() => {
      auth()
   }, [])

   const [form] = Form.useForm()

   const handleUpdate = async (values: any) => {
      //@ts-ignore
      if (values.password && values.password !== values.confirmPassword) {
         message.error({
            key,
            type: 'error',
            content: t('messages.passwordNotMatch'),
            duration: 2
         })
         return
      }
      else {
         //@ts-ignore
         updateProfile(USER_UPDATE, {
            endPoint: 'auth/updateProfile',
            body: values
         })
      }
   }

   useEffect(() => {
      if (loading) {
         messageApi.open({
            key,
            type: 'loading',
            content: t('messages.updateProfileLoading'),
            duration: 2
         })
      }
   }, [loading])

   useEffect(() => {
      if (data) {
         if (data.status) {
            message.success({
               key,
               type: 'success',
               content: t('messages.updateProfileSuccess'),
               duration: 2
            })
         } else {
            message.error({
               key,
               type: 'error',
               content: t('messages.' + data.message || 'messages.updateProfileError'),
               duration: 2
            })
         }
      }
   }, [data])

   const { isOpen, onOpen, onClose } = useDisclosure()

   useEffect(() => {
      axios.interceptors.response.use(
         (response: any) => {
            switch (response.data.status) {
               case 400:
                  onOpen()
                  return

               default:
            }

            return response
         },
         function (error) {
            if (error.response.status === 400) {
               if (error.response.data.action == 'profile') {
                  onOpen()
               }
            }
            return Promise.reject(error)
         }
      )
   }, [])

   useEffect(() => {
      //console.log(user.shouldUpdateProfile);
      if (user.shouldUpdateProfile) {
         onOpen()
      }
   }, [user])

   return (
      <main className="flex min-h-screen flex-grow">
         <Modal
            size="4xl"

            isOpen={isOpen}
            onClose={() => {
               onClose()
               dispatch(setShouldUpdateProfile(false))
            }}
            backdrop="blur"
         >
            <ModalContent>
               {onClose => (
                  <Form
                     // style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}
                     form={form}
                     onFinish={handleUpdate}
                     layout="vertical"
                     initialValues={{
                        name: row?.name,
                        email: row?.email,
                        last_name: row?.last_name,
                        country: row.country,
                        city: row.city,
                        street: row.street,
                        postal_code: row.postal_code,
                        phone_number: row.phone_number,
                        mobile_number: row.mobile_number,
                        date_of_birth: row.date_of_birth,
                        bank_owner: row.bank_owner,
                        bank_name: row.bank_name,
                        iban: row.iban,
                        bic: row.bic
                     }}
                  >
                     <ModalHeader className="flex flex-col gap-1">
                        <Title as="h5" className="font-semibold">
                           {t('messages.updateProfile')}
                        </Title>
                     </ModalHeader>
                     <ModalBody style={{
                        maxHeight: 'calc(100vh - 200px)',
                        overflowY: 'auto'
                     }}>
                        <Alert color="danger">{t('messages.mustUpdateProfile')}</Alert>
                        <div className="grid grid-cols-3 gap-x-5 gap-y-0">
                           <Form.Item
                              name="name"
                              label={t('common.name')}
                              rules={[
                                 {
                                    required: true,
                                    message: t('valiation.name_required')
                                 }
                              ]}
                           >
                              <Input
                                 className="w-full border border-gray-300 rounded-md px-3 py-2 outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                                 placeholder={t('common.name')}
                              />
                           </Form.Item>
                           <Form.Item
                              name="last_name"
                              label={t('common.last_name')}
                              rules={[
                                 {
                                    required: true,
                                    message: t('valiation.last_name_required')
                                 }
                              ]}
                           >
                              <Input
                                 className="w-full border border-gray-300 rounded-md px-3 py-2 outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                                 placeholder={t('common.last_name')}
                              />
                           </Form.Item>
                           <Form.Item
                              name="email"
                              label={t('common.email')}
                              rules={[
                                 {
                                    required: true,
                                    message: t('valiation.email_required')
                                 },
                                 {
                                    type: 'email',
                                    message: t('valiation.email_format')
                                 }
                              ]}
                           >
                              <Input
                                 className="w-full border border-gray-300 rounded-md px-3 py-2 outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                                 placeholder={t('common.email')}
                              />
                           </Form.Item>
                           <Form.Item
                              name="phone_number"
                              label={t('common.phone_number')}
                              rules={[
                                 {
                                    required: true,
                                    message: t('valiation.phone_number_required')
                                 }
                              ]}
                           >
                              <Input
                                 className="w-full border border-gray-300 rounded-md px-3 py-2 outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                                 placeholder={t('common.phone_number')}
                              />
                           </Form.Item>
                           <Form.Item
                              name="mobile_number"
                              label={t('common.mobile_number')}
                              rules={[
                                 {
                                    required: false,
                                    message: t('valiation.mobile_number_required')
                                 }
                              ]}
                           >
                              <Input
                                 className="w-full border border-gray-300 rounded-md px-3 py-2 outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                                 placeholder={t('common.mobile_number')}
                              />
                           </Form.Item>
                           <Form.Item
                              name="date_of_birth"
                              label={t('common.date_of_birth')}
                              rules={[
                                 {
                                    required: true,
                                    message: t('valiation.date_of_birth_required')
                                 }
                              ]}
                           >
                              <Input
                                 type="date"
                                 className="w-full border border-gray-300 rounded-md px-3 py-2 outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                                 placeholder={t('common.date_of_birth')}
                              />
                           </Form.Item>
                        </div>
                        <Divider />
                        <Alert color="info">{t('common.bankInfo')}</Alert>
                        <div className='grid grid-cols-2 gap-x-5'>
                           <Form.Item
                              name="bank_owner"
                              label={t('common.bank_owner')}
                              rules={[
                                 {
                                    required: false,
                                    message: t('valiation.bank_owner_required')
                                 }
                              ]}
                           >
                              <Input
                                 className="w-full border border-gray-300 rounded-md px-3 py-2 outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                                 placeholder={t('common.bank_owner')}
                              />
                           </Form.Item>
                           <Form.Item
                              name="bank_name"
                              label={t('common.bank_name')}
                              rules={[
                                 {
                                    required: false,
                                    message: t('valiation.bank_name_required')
                                 }
                              ]}
                           >
                              <Input
                                 className="w-full border border-gray-300 rounded-md px-3 py-2 outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                                 placeholder={t('common.bank_name')}
                              />
                           </Form.Item>
                           <Form.Item
                              name="iban"
                              label={t('common.iban')}
                              rules={[
                                 {
                                    required: false,
                                    message: t('valiation.iban_required')
                                 }
                              ]}
                           >
                              <Input
                                 className="w-full border border-gray-300 rounded-md px-3 py-2 outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                                 placeholder={t('common.iban')}
                              />
                           </Form.Item>
                           <Form.Item
                              name="bic"
                              label={t('common.bic')}
                              rules={[
                                 {
                                    required: false,
                                    message: t('valiation.bic_required')
                                 }
                              ]}
                           >
                              <Input
                                 className="w-full border border-gray-300 rounded-md px-3 py-2 outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                                 placeholder={t('common.bic')}
                              />
                           </Form.Item>
                        </div>
                        <Divider />
                        <Alert color="info">{t('messages.addressInfo')}</Alert>
                        <div className="grid grid-cols-3 gap-x-5">
                           <Form.Item
                              name="country"
                              label={t('common.country')}
                              rules={[
                                 {
                                    required: true,
                                    message: t('valiation.country_required')
                                 }
                              ]}
                           >
                              <Input
                                 className="w-full border border-gray-300 rounded-md px-3 py-2 outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                                 placeholder={t('common.country')}
                              />
                           </Form.Item>
                           <Form.Item
                              name="postal_code"
                              label={t('common.postal_code')}
                              rules={[
                                 {
                                    required: true,
                                    message: t('valiation.postal_code_required')
                                 }
                              ]}
                           >
                              <Input
                                 className="w-full border border-gray-300 rounded-md px-3 py-2 outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                                 placeholder={t('common.postal_code')}
                              />
                           </Form.Item>


                           <Form.Item
                              name="city"
                              label={t('common.city')}
                              rules={[
                                 {
                                    required: true,
                                    message: t('valiation.city_required')
                                 }
                              ]}
                           >
                              <Input
                                 className="w-full border border-gray-300 rounded-md px-3 py-2 outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                                 placeholder={t('common.city')}
                              />
                           </Form.Item>
                        </div>
                        <Form.Item
                           name="street"
                           label={t('common.street')}
                           rules={[
                              {
                                 required: true,
                                 message: t('valiation.street_required')
                              }
                           ]}
                        >
                           <Input
                              className="w-full border border-gray-300 rounded-md px-3 py-2 outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                              placeholder={t('common.street')}
                           />
                        </Form.Item>
                        <Divider />
                        <Alert color="info">{t('messages.updatePasswordInfo')}</Alert>
                        <Form.Item
                           name="old_password"
                           label={t('common.oldPassword')}
                           rules={[
                              {
                                 required: false,
                                 message: t('valiation.password_required')
                              }
                           ]}
                        >
                           <Input.Password
                              className="w-full border border-gray-300 rounded-md px-3 py-2 outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                              placeholder={t('common.password')}
                           />
                        </Form.Item>
                        <Form.Item
                           name="password"
                           label={t('common.new_password')}
                           rules={[
                              {
                                 required: false,
                                 message: t('valiation.password_required')
                              }
                           ]}
                        >
                           <Input.Password
                              className="w-full border border-gray-300 rounded-md px-3 py-2 outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                              placeholder={t('common.new_password')}
                           />
                        </Form.Item>
                        <Form.Item
                           name="confirmPassword"
                           label={t('common.confirmPassword')}
                           rules={[
                              {
                                 required: false,
                                 message: t('valiation.password_required')
                              }
                           ]}
                        >
                           <Input.Password
                              className="w-full border border-gray-300 rounded-md px-3 py-2 outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                              placeholder={t('common.confirmPassword')}
                           />
                        </Form.Item>
                     </ModalBody>
                     <ModalFooter>
                        <Button color="danger" variant="light" onPress={onClose}>
                           {t('buttons.cancel')}
                        </Button>
                        <Button color="primary" type="submit" isLoading={loading}>
                           {t('buttons.save')}
                        </Button>
                     </ModalFooter>
                  </Form>
               )}
            </ModalContent>
         </Modal>
         <Sidebar className={`fixed ${openSidebar ? 'block' : 'hidden'} dark:bg-gray-50 xl:block`} />

         <div
            className="flex w-full flex-col xl:ms-[270px] xl:w-[calc(100%-270px)] 2xl:ms-72 2xl:w-[calc(100%-288px)]"
            onClick={() => setOpenSidebar(false)}
         >
            <Header />
            <TradingViewWidget isDarkMode={false} />
            <div className="flex flex-grow flex-col px-4 pb-6 pt-2 @container md:px-5 lg:px-6 lg:pb-8 3xl:px-8 3xl:pt-4 4xl:px-10 4xl:pb-9">
               <Suspense fallback={<div>Loading...</div>}>
                  <Routes>
                     <Route path="/index" element={<EcommerceDashboard />} />
                     <Route path="/my-stocks/*" element={<MyStocks />} />

                     <Route path="/live-trading/*" element={<LiveTradingPage />} />
                     <Route path="/transaction/:type" element={<MySellStocksTable />} />
                     <Route path="/activities" element={<Activities />} />
                     <Route path="/stocks" element={<StocksPage />} />
                     <Route path="/referrals" element={<Referrals />} />
                     <Route path="/ipos" element={<Ipos />} />
                     <Route path="/invested" element={<Invested />} />
                     <Route path="/invested/payment-plan/:id" element={<PaymentPlan />} />
                     <Route path="/my-referrals" element={<MyReferrals />} />
                     <Route path="/my-ipos" element={<UserIpos />} />
                     <Route path="/my-invested" element={<UserInvested />} />
                     <Route path="/users/*" element={<UsersPage />} />
                     <Route path="/app-settings" element={<EditAppSettings />} />
                     <Route path="/language/index" element={<LanguageSettings />} />
                     <Route path="/languages/:id/edit" element={<EditLanguage />} />

                     <Route path="/language/create" element={<LanguageSettings />} />
                     <Route path="/app-settings" element={<EditAppSettings />} />

                     <Route path="*" element={<Navigate to="/dashboard/index" />} />

                     {/* Redirecting unknown url to 404 page */}
                     {/* <Route path="*" element={<Page404 />} /> */}
                  </Routes>
               </Suspense>
            </div>
         </div>
         <GlobalDrawer />
      </main>
   )
}

export default AppLayout
