import { routes } from "config/routes";
import { Card } from "antd";
import StatCards from "./Summary";
import { useTranslation } from "react-i18next";
import PageHeader from "components/page-header";
import TradingMarketing from "components/layouts/TradingMarketing";
import Trading from "./Trading";

export default function EcommerceDashboard() {
  const { t } = useTranslation();
  const pageHeader = {
    title: t("aside.dashboard"),
    breadcrumb: [
      {
        href: routes.dashboard,
        name: t("aside.dashboard"),
      },

    ],
  };

  return (
    <>
      {/* <div>
      test
    </div> */}
      <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
        <div className="mt-4 flex items-center gap-3 @lg:mt-0"></div>
      </PageHeader>
      <div className="@container">
        <StatCards />
        <div className="mt-5 grid grid-cols-2 gap-10">
          <Card title={t("dashboard.tradingMarketing")}>
            <TradingMarketing />
          </Card>
          <Card title={t("dashboard.trading")}>
            <Trading />
          </Card>
        </div>
      </div>
    </>
  );
}
