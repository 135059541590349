import { Card } from '@nextui-org/react'
import { Tabs } from 'antd'
import React from 'react'
import Activities from './Activities'
import Referral from './Referral'
import StockTable from './StockTable'
import SellStockTable from './SellStockTable'
import Invested from './Invested'
import Ipos from './Ipos'

interface ProfileProps {
   user: any
   activities: any
   buyStocks: any
   sellStocks: any
   referrals: any
   investeds: any
   ipos: any
   loading: boolean | undefined
}

function Profile(props: ProfileProps) {
   const { user, activities, buyStocks, sellStocks, referrals, investeds, ipos, loading } = props
   console.log(props)

   if (loading) {
      return <div>Loading...</div>
   }

   return (
      <Card className="p-10">
         <Tabs defaultActiveKey="2">
            {/* <Tabs.TabPane tab="Activities" key="1">
               <Activities data={activities || []} />
            </Tabs.TabPane> */}
            <Tabs.TabPane tab="Referral" key="2">
               <Card>
                  <Referral data={referrals || []} />
               </Card>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Buy Stocks" key="3">
               <Card>
                  <StockTable data={buyStocks || []} />
               </Card>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Sell Stocks" key="4">
               <Card>
                  <SellStockTable data={sellStocks || []} />
               </Card>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Invested" key="5">
               <Card>
                  <Invested data={investeds || []} />
               </Card>
            </Tabs.TabPane>
            <Tabs.TabPane tab="IPO" key="6">
               <Card>
                  <Ipos data={ipos || []} />
               </Card>
            </Tabs.TabPane>
         </Tabs>
      </Card>
   )
}

export default Profile
