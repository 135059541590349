import { TableColumnProps } from 'antd'
import TableComponents from 'components/Table'
import { MoneyFormat2 } from 'hooks/currency'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface InvestedProps {
   data: any
}

function Invested(props: InvestedProps) {
   const { t } = useTranslation()
   const columns: TableColumnProps<any>[] = [
      {
         title: t('common.amount'),
         dataIndex: 'amount',
         key: 'amount',
         render: (amount: any) => {
            return <div className="flex items-center gap-3">{MoneyFormat2(amount)}</div>
         }
      },
      {
         title: t('common.startDate'),
         dataIndex: 'start_date',
         key: 'start_date',
         render: (start_date: any) => {
            return <div className="flex items-center gap-3">{moment(start_date).format('YYYY-MM-DD')}</div>
         }
      },
      {
         title: t('common.endDate'),
         dataIndex: 'end_date',
         key: 'end_date',
         render: (end_date: any) => {
            return <div className="flex items-center gap-3">{moment(end_date).format('YYYY-MM-DD')}</div>
         }
      },
      {
         title: t('common.period'),
         dataIndex: 'period',
         key: 'period',
         render: (period: any) => {
            return <div className="flex items-center gap-3">{period} month</div>
         }
      },
      {
         title: t('common.rate'),
         dataIndex: 'rate',
         key: 'rate',
         render: (rate: any) => {
            return <div className="flex items-center gap-3">{rate.toString().replace('.', ',')}%</div>
         }
      },
      {
         title: t('common.total'),
         dataIndex: 'period',
         key: 'period',
         render: (period: any, record: any) => {
            return (
               <div className="flex items-center gap-3">
                  {MoneyFormat2((record.rate * record.amount) / 100)}
               </div>
            )
         }
      }
   ]
   return (
      <div>
         <TableComponents columns={columns} data={props.data} pagination={false} loading={false} />
      </div>
   )
}

export default Invested
