import axios from "axios";
import { FetchProps } from "./interface";

export const REFERRALS_LIST_URL = '/referrals?page=:page';

export const USER_REFERRALS_LIST_URL = '/user/referrals?page=:page';

export const REFERRALS_STORE_URL = '/referrals';

export const REFERRALS_UPDATE_URL = '/referrals';

export const REFERRALS_LIST = async (props:FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .get(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            return { error };
        });
};

export const REFERRALS_STORE = async (props:FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .post(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            return { error };
        });
}

export const REFERRALS_UPDATE = async (props:FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .put(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            return { error };
        });
}

export const REFERRALS_DELETE = async (props:FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .delete(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            return { error };
        });
}

export const REFERRALS_SHOW = async (props:FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .get(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            return { error };
        });
}