import React, { useEffect, useState } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import type { UploadProps } from 'antd'
import { Button, message, Upload } from 'antd'
import { apiUrl } from 'api'
import axios from 'axios'

interface Props {
   file: {
      name: string
      url: string
   }
   form: any
}

const App = (props: Props) => {
   const [fileList, setFileList] = useState<any[]>([])

   useEffect(() => {
      if (props.file) {
         setFileList([
            {
               uid: '-1',
               name: 'logo',
               status: 'done',
               url: props.file.url
            }
         ])
      }
   }, [props.file])

   const uploadProps: UploadProps = {
      name: 'file',
      multiple: false,
      maxCount: 1,
      listType: 'picture',
      action: apiUrl + '/api/update-logo',
      fileList: fileList,
      headers: {
         authorization: 'Bearer ' + localStorage.getItem('token')
      }
   }

   const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
      console.log(newFileList)
      setFileList(newFileList)
   }

   useEffect(() => {
      if (fileList.length > 0) {
         const file = fileList[0]

         if (file.status === 'done') {
            props.form.setFieldsValue({
               logo: 'images/' + file.response?.data
            })
         }
      }
   }, [fileList])

   return (
      <Upload onChange={handleChange} {...uploadProps}>
         <Button icon={<UploadOutlined />}>Click to Upload</Button>
      </Upload>
   )
}

export default App
